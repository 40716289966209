import { useCatalogQuery } from '@chordcommerce/gatsby-theme-autonomy/src/hooks/graphql/queries/use-catalog';

const useProduct = ({ slug }) => {
  const { catalog: { collections = [] } = { collections: [] } } = useCatalogQuery();

  const products = collections.reduce((acc, collection) => [...acc, ...collection.products], []);

  return products.find((product) => product.slug === slug);
};

export default useProduct;
