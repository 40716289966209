import React from 'react';
import { Flex, Spinner } from 'theme-ui';

export const CartSpinner = () => {
  return (
    <Flex
      sx={{
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        mb: '1.5rem',
      }}
    >
      <Spinner size="50" color="alizarinCrimson" />
    </Flex>
  );
};
