export const customBoxSkus = [
  'pad-night',
  'pad-day',
  'tampon-light',
  'tampon-regular',
  'tampon-super',
  'liners-original',
  'pad-day-12',
  'pad-ngt-12',
  'pad-lnr-24',
  'pad-lng-ngt-12',
];
