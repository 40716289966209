import { useEffect, useState } from 'react';

const useSelectedOrFirstAvailableVariant = (product) => {
  const [option1Values, setOption1Values] = useState();

  const [selectedOption1, setSelectedOption1] = useState();
  const [selectedOption2, setSelectedOption2] = useState();

  const [selectedVariant, setSelectedVariant] = useState();

  const selectVariantWithOptions = (optionSlugs) => {
    const matchingVariant = product.variants.find((variant) => {
      const optionValuesSlugs = variant.optionValues?.map((v) => v.slug) ?? [];

      return (
        optionValuesSlugs.every((s) => optionSlugs.includes(s)) &&
        optionSlugs.every((s) => optionValuesSlugs.includes(s))
      );
    });

    return matchingVariant;
  };

  const selectOption1 = (option) => {
    setSelectedOption1(option);

    const selectedOption2IsStillAvailable =
      option.combinations.find((v) => v.slug === selectedOption2.slug) !== undefined;

    const availableOption2 = selectedOption2IsStillAvailable
      ? selectedOption2
      : option.combinations[0];

    const variant = selectVariantWithOptions([option.slug, availableOption2.slug].filter(Boolean));

    setSelectedOption2(availableOption2);
    setSelectedVariant(variant);
  };

  const selectOption2 = (option) => {
    const variant = selectVariantWithOptions([selectedOption1.slug, option.slug].filter(Boolean));

    setSelectedOption2(option);
    setSelectedVariant(variant);
  };

  useEffect(() => {
    const optionTypes = product.optionTypes ?? [];

    if (!optionTypes.length) {
      setOption1Values([]);
      setSelectedOption1(undefined);
      setSelectedOption2(undefined);
      setSelectedVariant(product.variants.find((variant) => variant.available));
      return;
    }

    const mappedOption1Values =
      optionTypes[0]?.optionValues
        ?.map((value) => {
          const variantsWithOption1Value = product.variants.filter(
            (variant) =>
              variant.available && variant.optionValues?.find((v) => v.slug === value.slug)
          );

          const availableOption2Combinations = variantsWithOption1Value.reduce(
            (acc, variant) => [
              ...acc,
              ...(variant.optionValues ?? []).filter((v) => v.slug !== value.slug),
            ],
            []
          );

          return {
            ...value,
            available: !!variantsWithOption1Value.length,
            combinations: availableOption2Combinations,
          };
        })
        .reverse() ?? [];

    const firstAvailableOption1 = [...mappedOption1Values]
      .sort((a, b) => b.combinations.length - a.combinations.length)
      .find((value) => value.available);
    const firstAvailableOption2 = firstAvailableOption1?.combinations[0];

    setOption1Values(mappedOption1Values);
    setSelectedOption1(firstAvailableOption1);
    setSelectedOption2(firstAvailableOption2);

    const firstAvailableVariant = selectVariantWithOptions(
      [firstAvailableOption1?.slug, firstAvailableOption2?.slug].filter(Boolean)
    );

    setSelectedVariant(firstAvailableVariant);
  }, [JSON.stringify(product)]);

  return {
    option1Values,
    selectedOption1,
    selectedOption2,
    selectedVariant,
    selectOption1,
    selectOption2,
  };
};

export default useSelectedOrFirstAvailableVariant;
