/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React from 'react';
import { Flex, jsx, Text } from 'theme-ui';
import CheckoutButton from '~/components/Cart/CheckoutButton';
import { DividerLine } from '~/components/Generic/DividerLine/DividerLine';

const CartSummary = ({
  total,
  displayTotal,
  orderConfirmed = false,
  promotions,
  tCChecked,
  outOfStock,
  estimatedTotal,
  ...props
}) =>
  orderConfirmed ? (
    <>
      <DividerLine height="0.1rem" color="chatelle" />
      <Flex sx={{ width: '100%', fontSize: '2rem', fontWeight: 'bold', mb: '3rem' }}>
        <Text>Total</Text>
        <Text sx={{ color: 'discountGreen', ml: '1.5rem' }}>{displayTotal}</Text>
      </Flex>
    </>
  ) : (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 'auto',
        flexgrow: 1.5,
        justifyContent: 'center',
        alignItems: !orderConfirmed ? null : 'center',
        width: '100%',
        backgroundColor: 'canary',
        px: orderConfirmed ? 0 : '1.5rem',
        py: '1.5rem',
        color: orderConfirmed ? 'alizarinCrimson' : 'canary',
        position: 'sticky',
        bottom: 0,
        right: 0,
        borderTop: '0.2rem solid #CF2029',
      }}
      {...props}
    >
      <CheckoutButton
        tCChecked={tCChecked}
        outOfStock={outOfStock}
        displayTotal={displayTotal}
        total={total}
        estimatedTotal={estimatedTotal}
      />
    </Flex>
  );

export default CartSummary;
