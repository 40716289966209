import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import NavBar from '~/components/Nav/Bar';

export const Nav = ({ menuIsOpen, toggleMenu, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        logo: contentfulAsset(title: { eq: "logo-crimson" }) {
          gatsbyImageData(quality: 80)
        }
        burger: contentfulAsset(title: { eq: "burger-menu-svg" }) {
          svg {
            content # SVG content optimized with SVGO
          }
          file {
            contentType
            url
          }
          gatsbyImageData(quality: 80)
        }
        bag: contentfulAsset(title: { eq: "bag-icon" }) {
          gatsbyImageData(quality: 80)
        }
      }
    `
  );

  const lineItems = useSelector((state) => state.cart.data.lineItems, shallowEqual);

  // only count the Total Items in the cart, Build-A-Box is 1 item
  const itemCount =
    lineItems &&
    lineItems.reduce((acc, item) => {
      if (
        !item.variant.name.includes('Pad') &&
        !item.variant.name.includes('Liner') &&
        !item.variant.name.includes('Tampon')
      ) {
        acc += 1;
      }
      return acc;
    }, 0);

  return (
    <NavBar
      menuIsOpen={menuIsOpen}
      itemCount={itemCount}
      toggleMenu={toggleMenu}
      {...props}
      data={data}
    />
  );
};

export default Nav;
