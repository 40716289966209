/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useContext, useRef, Fragment, useState } from 'react';
import { useAnalytics, useCart, utils } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from 'gatsby';
import { Box, Container, Flex, IconButton, Text, jsx, Spinner, Button } from 'theme-ui';
import _ from 'lodash';
import CartContext from '~/contexts/CartContext';
import LineItems from '~/components/Cart/LineItems';
import CartSummary from '~/components/Cart/Summary';
import useOnClickOutside from '~/hooks/components/use-on-click-outside';
import useLockBodyScroll from '~/hooks/components/use-lock-body-scroll';
import Spacer from '~/components/Generic/Layout/Spacer';
import Close from '~/assets/images/icons/close-thick.svg';
import checkFreeShipping from '~/utils/misc/checkFreeShipping';
import { configureFatMedia } from '~/utils/fat-media';
import { getAllFormatedPromotions } from '~/utils/promotions/formatPromotions';

const CartContainer = ({ children }) => {
  const [isCartOpen, setCartIsOpen] = useContext(CartContext);

  useLockBodyScroll(isCartOpen);

  const cartRef = useRef();

  useOnClickOutside(cartRef, () => {
    setCartIsOpen(false);
  });

  const handleClick = () => {
    setCartIsOpen(false);
  };

  return (
    <Container
      ref={cartRef}
      variant="fullwidth"
      sx={{
        width: ['100vw', '60vw', '30vw'],
        minWidth: ['auto', '375px', '375px'],
        maxWidth: '640px',
        height: '100vh',
        position: 'fixed',
        top: 0,
        visibility: `${isCartOpen ? 'visible' : 'hidden'}`,
        right: 0,
        transform: `${isCartOpen ? 'translateX(0%)' : 'translateX(100%)'}`,
        zIndex: 2147483648,
        backgroundColor: 'lightPurple',
        transition: 'all 0.5s ease',
        boxShadow: `0px 4px 4px rgba(0,0,0,${isCartOpen ? '0.25' : '0'})`,
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        {children}
      </Flex>
    </Container>
  );
};

const Cart = ({
  lineItems,
  total,
  itemTotal,
  displayTotal,
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  shipTotal,
  promotions,
  eligibleForFreeShipping,
  amountNeededForFreeShipping,
  removeFromCart,
}) => {
  const [, setCartIsOpen] = useContext(CartContext);
  const [tCChecked, setTCChecked] = useState(false);
  const [outOfStock, setOutOfStock] = useState({});

  const handleTCCheck = () => {
    setTCChecked((prev) => !prev);
  };

  const handleClick = () => {
    setCartIsOpen(false);
  };

  const shippingCost = Number(shipTotal) > 0 ? 0 : 6.99;
  const estimatedTotal = eligibleForFreeShipping ? Number(total) : Number(total) + shippingCost;

  return (
    <CartContainer>
      <Flex
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100vh',
        }}
      >
        <Flex
          sx={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '17px',
            height: '54px',
            maxHeight: '54px',
            flexGrow: 1,
            backgroundColor: 'canary',
            '& > div:first-of-type': {
              marginRight: '20px',
            },
            px: '2rem',
            py: '1.6rem',
            borderBottom: '0.2rem solid #CF2029',
          }}
        >
          <IconButton
            onClick={handleClick}
            tabIndex="0"
            aria-label="Close cart"
            sx={{
              color: 'alizarinCrimson',
              width: '51px',
              height: '51px',
              padding: 0,
              outline: 'none',
              position: 'absolute',
              left: 0,
              path: {
                stroke: 'alizarinCrimson',
              },
            }}
          >
            <Close sx={{ width: '100%', height: '100%' }} />
          </IconButton>
          <Text
            sx={{
              fontWeight: 700,
              color: 'alizarinCrimson',
            }}
          >
            YOUR BAG
          </Text>
        </Flex>
        {/* Show empty cart when only donation box is left in cart */}
        {_.size(lineItems?.filter((item) => item.variant.sku !== 'donation-box-5')) > 0 ? (
          <Fragment>
            <LineItems
              items={lineItems}
              amountNeededForFreeShipping={amountNeededForFreeShipping}
              eligibleForFreeShipping={eligibleForFreeShipping}
              removeFromCart={removeFromCart}
              itemTotal={itemTotal}
              displayItemTotal={displayItemTotal}
              displayTaxTotal={displayTaxTotal}
              displayShipTotal={displayShipTotal}
              promotions={promotions}
              total={total}
              displayTotal={displayTotal}
              shipTotal={shipTotal}
              handleTCCheck={handleTCCheck}
              setOutOfStock={setOutOfStock}
              estimatedTotal={estimatedTotal}
            />
            <CartSummary
              items={lineItems}
              eligibleForFreeShipping={eligibleForFreeShipping}
              promotions={promotions}
              tCChecked={tCChecked}
              outOfStock={outOfStock}
              displayTotal={displayTotal}
              estimatedTotal={estimatedTotal}
            />
          </Fragment>
        ) : (
          <Flex
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              fontSize: '1.6rem',
              color: 'alizarinCrimson',
              fontWeight: 'bold',
              a: {
                fontSize: '15px',
              },
            }}
          >
            <Text>Your bag is empty</Text>
            <Spacer height={['15px']} />
            <Button
              as={Link}
              to="/shop"
              onClick={handleClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '205px',
                height: '45px',
                borderRadius: '.625rem',
                backgroundColor: 'canary',
                mb: '71px',
              }}
            >
              Shop
            </Button>
          </Flex>
        )}
      </Flex>
    </CartContainer>
  );
};

const CartLoading = () => (
  <CartContainer>
    <Flex
      sx={{
        textAlign: 'center',
        width: '100%',
        height: '100vh',
        padding: ['10rem 1.25rem', '12rem 1.25rem'],
        marginRight: [null, '1rem'],
        marginBottom: ['1rem', null],
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner size="50" />
    </Flex>
  </CartContainer>
);

const CartPage = () => {
  const [isCartOpen] = useContext(CartContext);
  const { cart, loadCart, removeFromCart, isFetching } = useCart();
  const { trackCartViewed } = useAnalytics();

  configureFatMedia({ pageType: 'CART' });

  useEffect(() => {
    const loadCartState = async () => {
      try {
        await loadCart();
      } catch (error) {
        console.log(error, { source: 'Cart' });
      }
    };
    if (isCartOpen) {
      loadCartState();
    }
  }, [isCartOpen, loadCart]);

  useEffect(() => {
    const trackCartView = async () => {
      try {
        await trackCartViewed({ products: cart?.lineItems ?? [] });
      } catch (error) {
        console.log(error, { source: 'Cart' });
      }
    };
    if (isCartOpen) {
      trackCartView();
    }
  }, [trackCartViewed, isCartOpen]);

  const freeShippingThreshold = Number(process.env.GATSBY_FREE_SHIPPING_THRESHOLD || 0);
  const amountNeededForFreeShipping = freeShippingThreshold - Number(cart.total);

  if (isFetching && cart.lineItems && cart.lineItems.length === 0) return <CartLoading />;

  const {
    itemTotal,
    displayTotal,
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    shipTotal,
    lineItems,
    total,
  } = cart;

  const promotions = getAllFormatedPromotions(cart);
  const shippingPromo = promotions?.filter((p) => p.label?.includes('Shipping')).length;
  const lineItemsNotGiftCards =
    lineItems?.filter((item) => item.variant.slug !== 'gift-card') ?? [];

  const eligibleForFreeShipping =
    shippingPromo || lineItemsNotGiftCards.length === 0 || checkFreeShipping(itemTotal, shipTotal);

  return (
    <>
      <Cart
        lineItems={lineItems}
        displayTotal={displayTotal}
        itemTotal={itemTotal}
        displayItemTotal={displayItemTotal}
        displayTaxTotal={displayTaxTotal}
        displayShipTotal={displayShipTotal}
        shipTotal={shipTotal}
        promotions={promotions}
        amountNeededForFreeShipping={amountNeededForFreeShipping}
        eligibleForFreeShipping={eligibleForFreeShipping}
        removeFromCart={removeFromCart}
        total={total}
      />
    </>
  );
};

export default CartPage;
