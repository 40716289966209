/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

// Import the new rendering and the render node definitions
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

/**
 * A default placeholder template for unsupported nodes.
 */
const defaultInline = (_type, node) => (
  <span key={node.data.target.sys.id}>
    type: {node.nodeType} id: {node.data.target.sys.id}
  </span>
);

/**
 * Setting the rendering options. Same as:
 * https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
 *
 * Also see https://github.com/gatsbyjs/gatsby/pull/25249 for more implementation details.
 */
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    [BLOCKS.DOCUMENT]: (_node, children) => children,
    [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
    [BLOCKS.HR]: () => <hr />,
    [BLOCKS.HEADING_1]: (_node, children) => <h1>{children}</h1>,
    [BLOCKS.HEADING_2]: (_node, children) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_node, children) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (_node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (_node, children) => <h6>{children}</h6>,
    [BLOCKS.EMBEDDED_ASSET]: (node, _children) => <GatsbyImage {...node.data.target} />,
    [BLOCKS.EMBEDDED_ENTRY]: (_node, children) => <div>{children}</div>,
    [BLOCKS.UL_LIST]: (_node, children) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_node, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_node, children) => <li>{children}</li>,
    [BLOCKS.QUOTE]: (_node, children) => <blockquote>{children}</blockquote>,
    [INLINES.ENTRY_HYPERLINK]: (node, _children) => (
      <Link sx={{ variant: 'text.link' }} to={`/${node.data.target.slug}`}>
        {node.content[0].value}
      </Link>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a sx={{ variant: 'text.link' }} href={node.data.uri} target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
    [INLINES.ASSET_HYPERLINK]: (node, _children) => defaultInline(INLINES.ASSET_HYPERLINK, node),
    [INLINES.EMBEDDED_ENTRY]: (node, _children) => defaultInline(INLINES.EMBEDDED_ENTRY, node),
  },
};

const RichTextRenderer = ({ richText, optionsOverride = {}, as = 'div', ...props }) => {
  if (!richText) return <Fragment />;

  if (as === 'p')
    return (
      <p {...props}>
        {renderRichText(richText, {
          renderMark: { ...options.renderMark, ...optionsOverride.renderMark },
          renderNode: { ...options.renderNode, ...optionsOverride.renderNode },
        })}
      </p>
    );

  if (as === 'span')
    return (
      <span {...props}>
        {renderRichText(richText, {
          renderMark: { ...options.renderMark, ...optionsOverride.renderMark },
          renderNode: { ...options.renderNode, ...optionsOverride.renderNode },
        })}
      </span>
    );

  if (as === 'div')
    return (
      <div {...props}>
        {renderRichText(richText, {
          renderMark: { ...options.renderMark, ...optionsOverride.renderMark },
          renderNode: { ...options.renderNode, ...optionsOverride.renderNode },
        })}
      </div>
    );

  return (
    <Fragment>
      {renderRichText(richText, {
        renderMark: { ...options.renderMark, ...optionsOverride.renderMark },
        renderNode: { ...options.renderNode, ...optionsOverride.renderNode },
      })}
    </Fragment>
  );
};

RichTextRenderer.propTypes = {
  richText: PropTypes.object,
};

export default RichTextRenderer;
