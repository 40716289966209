/** @jsx jsx */
import { Link } from 'gatsby';
import { jsx } from 'theme-ui';

import AugustLogo from '~/assets/images/August-Logo.svg';

const NavMenuLogo = () => (
  <Link
    to="/"
    aria-label="Home"
    sx={{
      mt: ['-2px', 0, 0],
      height: ['26px', '41px', '50px'],
      width: ['89px', '139px', '168px'],
      '& > svg': {
        height: '100%',
        width: '100%',
      },
    }}
  >
    <AugustLogo />
  </Link>
);

export default NavMenuLogo;
