/* eslint-disable prefer-template */
/** @jsx jsx */
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Flex, Spinner, jsx } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import updateCreatorIQMetric from '~/utils/misc/updateCreatorIQMetric';
import { generateEfficientQuanArr } from '~/utils/addToBag/generateEfficientQuanArr';
import MessageContext from '~/contexts/MessageContext';
import CartContext from '~/contexts/CartContext';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import BoxBuilderPopUpContext, { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import { trackCustomEvent } from '~/utils/analytics';
import { customBoxSkus } from '~/utils/GetSkus';
import { updateStoredTags } from '~/utils/misc/storeTags';

const ProductAddToBagButton = ({
  soldOut = false,
  comingSoon = false,
  disabled = false,
  price,
  quantity,
  regularPrice,
  sku,
  handleAddToCart,
  subscription,
  monthly,
  totalsArr,
  cartTotalArr,
  prodVariantsNoBox,
  prodVariants,
  prodBox,
  maxQuantity,
  activeTotalQuantity,
  interval,
  length: subIntervalLength,
  product,
  totalPrice,
  oneTime,
  disabledError,
  isItWidget = false,
  handleShowDeliveryError,
  setSelectedTab,
  cartInterval,
  selectedInterval,
  ...props
}) => {
  const { cart, modifyCart, loadCart } = useCart();
  const [, setCartIsOpen] = useContext(CartContext);
  const {
    buttonRef,
    dynamicButton,
    setDynamicButton,
    buttonIsLoading,
    setButtonIsLoading,
    setIsReplacingBox,
  } = useContext(BoxBuilderPopUpContext);
  const [, setMessage] = useContext(MessageContext);
  const { closeModal, setDidAddToCart } = useBoxBuilder();

  const isIncrementB = process.env.GATSBY_TEST_B === 'true';
  const eventNameSuffix = isIncrementB ? ' Split Test B' : ' Split Test A';

  // TODO create an updated prodvariants here Q: does it matter if this is on contentful? Maybe?? as the price will need to be found
  // TODO 2. Same logic for non sub products

  // Utilize the prodVariants no box to create the getSku & get Increments button
  const getSku = (index) => prodVariantsNoBox[index].variants[0].sku;
  const getIncrement = (index) => prodVariantsNoBox[index].increment;
  const nonEfficSkus = ['tampon-box'];

  const cartSkus = cart.lineItems?.map((item) => item.variant.sku) ?? [];
  const boxInCart = cart.lineItems?.find((item) => item.variant.sku === prodBox.sku);
  const localSubItemSkus = customBoxSkus;
  const localSubItems = cart.lineItems?.filter((item) =>
    localSubItemSkus.includes(item?.variant?.sku)
  );

  // check if the cart box and widget box are same
  const quantityChange = JSON.stringify(cartTotalArr) !== JSON.stringify(totalsArr);
  const isBoxUpdated =
    quantityChange || JSON.stringify(selectedInterval) !== JSON.stringify(cartInterval);

  const handleCloseModel = () => {
    setIsReplacingBox(false);
    closeModal();
    setTimeout(() => {
      setCartIsOpen(true);
      setButtonIsLoading(false);
    }, 500);
  };

  // eslint-disable-next-line consistent-return
  const addMultToCart = async () => {
    // Both the Below definitions add the Box item to the arr that will be populated with the products

    // ** 1. Populate both product arr's with just their 'box' item initially

    const prodsArr = [{ quantity: subIntervalLength || 1, sku: product.variants[0].sku }];

    const subsProdsArr = interval
      ? [
          {
            quantity: 1,
            sku: product.variants[0].sku,
            subscription_line_items_attributes: [
              {
                interval_length: interval.length,
                interval_units: interval.unit[0],
              },
            ],
          },
        ]
      : [];

    if (typeof subscription !== 'undefined' && typeof interval !== 'undefined') {
      // *** SUBSCRIPTION PRODUCT API REQ ********************************

      //  1. create the subTotalsQuanArr from the totalsArr prop, which is the selected quantity * the interval

      // const subTotalsQuanArr = totalsArr.map((quan) => subIntervalLength * quan);

      // 2.  Get effiicient quantites for items

      const efficientGeneratedArr = await generateEfficientQuanArr(
        totalsArr,
        prodBox,
        prodVariantsNoBox
      );
      // 3. Populate subProdsArr to use as payload for API request w/ correct items and quantities
      // TODO refactor this not to suck

      if (nonEfficSkus.includes(prodBox.sku)) {
        totalsArr.map((itemTotal, index) => {
          if (itemTotal > 0) {
            return subsProdsArr.push({
              quantity: itemTotal / getIncrement(index),
              sku: getSku(index),
              subscription_line_items_attributes: [
                {
                  interval_length: interval.length,
                  interval_units: interval.unit[0],
                },
              ],
            });
          }
          return null;
        });
      } else {
        efficientGeneratedArr.map((itemTotal, index) => {
          if (itemTotal > 0) {
            return subsProdsArr.push({
              quantity: itemTotal,
              sku: getSku(index),
              subscription_line_items_attributes: [
                {
                  interval_length: interval.length,
                  interval_units: interval.unit[0],
                },
              ],
            });
          }
          return null;
        });
      }

      const lineItems = subsProdsArr.filter((li) => !!li?.sku);

      const intervalEventName = (interval.slug || '')
        .split('-')
        .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
        .join(' ');

      if (process.env.GATSBY_TRACK_SPLIT_TEST === 'true') {
        trackCustomEvent(`Added to Cart${eventNameSuffix}`, { quantity: activeTotalQuantity });
      }

      await modifyCart({ attributes: { lineItemsAttributes: lineItems } });
      setDidAddToCart(true);
      await updateCreatorIQMetric();
      handleCloseModel();
    } else {
      // *** NON SUBSCRIPTION PRODUCT API REQ
      // 1. Generate Efficient quantities arr
      const oneTimeEfficientArr = await generateEfficientQuanArr(
        totalsArr,
        prodBox,
        prodVariantsNoBox
      );
      // 2. Map through eff quantities to populate payload arr
      // TODO refactor this not to suck
      if (nonEfficSkus.includes(prodBox.sku)) {
        totalsArr.map((itemTotal, index) => {
          if (totalsArr[index] > 0) {
            return prodsArr.push({ quantity: itemTotal / getIncrement(index), sku: getSku(index) });
          }
          return null;
        });
      } else {
        oneTimeEfficientArr.map((itemTotal, index) => {
          if (itemTotal > 0) {
            return prodsArr.push({
              quantity: itemTotal,
              sku: getSku(index),
            });
          }
          return null;
        });
      }

      const lineItems = prodsArr.filter((li) => !!li?.sku);

      trackCustomEvent(`Buy Once Selected${eventNameSuffix}`);
      trackCustomEvent(`Added to Cart${eventNameSuffix}`, { quantity: activeTotalQuantity });
      const response = await modifyCart({ attributes: { lineItemsAttributes: lineItems } });
      if (typeof window !== 'undefined') {
        window.ttq.track('AddToCart', { value: cart.total, currency: 'USD' });
      }
      await updateCreatorIQMetric();
      handleCloseModel();
      return response?.data;
    }
  };

  const checkMinProdQuan = async () => {
    // eslint-disable-next-line no-lonely-if
    if (boxInCart) {
      setIsReplacingBox(true);
      const removeArr = [{ id: boxInCart.id, quantity: 0 }];
      try {
        localSubItems.map(async (item) => {
          removeArr.push({ id: item.id, quantity: 0 });
        });
        await modifyCart({ attributes: { lineItemsAttributes: removeArr } });
      } catch (error) {
        console.log(error);
      }
    }
    await addMultToCart();
  };
  const handleSubmit = async (event) => {
    event?.preventDefault();
    if (disabled || comingSoon || soldOut) return;

    if (disabledError === 'Please select a delivery option') {
      setSelectedTab(1);
      handleShowDeliveryError();
      return;
    }

    if (boxInCart && !isBoxUpdated) {
      handleCloseModel();
      return;
    }

    // Only update the tags to edited if the quantity change is due to user input.
    // As Pre-builtboxes can also trigger quantity change
    // so we check 'dynamicButton' to confirm if its a quantity change from user or by adding a new pre build box
    if (quantityChange && !dynamicButton) {
      updateStoredTags(null, null, {
        tagKey: 'preBuildBox',
        tagValue: {
          edited: true,
        },
      });
    }

    setButtonIsLoading(true);
    setMessage(null);
    await loadCart();

    try {
      if (activeTotalQuantity <= 0) {
        setMessage(`You haven't selected any items`);
        setButtonIsLoading(false);
        setSelectedTab(1);
      } else if (activeTotalQuantity < 20) {
        setMessage(`Please select at least 20 total units of product`);
        setButtonIsLoading(false);
        setSelectedTab(1);
      } else {
        // This is the Success state
        checkMinProdQuan();
      }
    } catch (error) {
      setMessage('error.api.default');
    }

    setTimeout(() => {
      setMessage(null);
    }, 4000);
  };

  useEffect(() => {
    dynamicButton && handleSubmit();
    setDynamicButton(false);
  }, [dynamicButton]);

  useEffect(() => {
    const checkVisibility = async () => {
      document.addEventListener('visibilitychange', async () => {
        if (document.hidden) {
          console.log('not visible');
        } else {
          setButtonIsLoading(true);
          await loadCart();
          console.log('cart loading');
          setTimeout(() => {
            setButtonIsLoading(false);
          }, 1000);
        }
      });
    };
    checkVisibility();
  }, []);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      sx={{
        position: !isItWidget ? 'relative' : 'unset',
        display: !isItWidget ? 'flex' : 'unset',
        justifyContent: !isItWidget ? 'flex-end' : 'unset',
        width: !isItWidget ? ['90%', '80%', '60%'] : '100%',
      }}
      ref={buttonRef}
    >
      <Button
        disabled={disabled || comingSoon || soldOut || buttonIsLoading}
        {...props}
        type="submit"
        sx={{
          position: !isItWidget ? 'relative' : 'unset',
          zIndex: 1,
          outline: 'none',
          variant: isItWidget
            ? 'buttons.widgetButton'
            : oneTime
            ? 'buttons.oneTime'
            : 'buttons.subscription',
          width: '100%',
          fontSize: ['14px'],
          px: ['1.6rem !important'],
          backgroundColor:
            disabled ||
            comingSoon ||
            soldOut ||
            disabledError ||
            (boxInCart && !isBoxUpdated && !buttonIsLoading)
              ? 'chatelle'
              : isItWidget || buttonIsLoading
              ? 'alizarinCrimson'
              : 'canary',
        }}
        py={monthly ? 10 : 3}
      >
        <Flex
          sx={{
            alignItems: 'center',
            flexGrow: 1,
            height: '75%',
            justifyContent: 'center',
            padding: ['0 11px', null, '0 22px'],
            color: isItWidget ? 'canary' : 'alizarinCrimson',
          }}
        >
          {buttonIsLoading && <Spinner data-testid="spinner" size="15" color="inherit" />}
          {!buttonIsLoading && (
            <>
              {boxInCart && (isBoxUpdated ? 'Update Cart' : 'Go To Cart')}
              {comingSoon && 'product.coming_soon'}
              {soldOut && 'product.sold_out'}
              {!comingSoon && !soldOut && !boxInCart && 'ADD TO BAG'}
            </>
          )}
        </Flex>
        {!!isItWidget && <ArrowRight />}
      </Button>
    </form>
  );
};

export default ProductAddToBagButton;
