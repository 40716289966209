import React from 'react';
import { Box } from 'theme-ui';

export const BlurryOverlay = ({ boxPopUpOpen }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100vw',
        maxWidth: '100%',
        height: document.body.scrollHeight,
        maxHeight: document.body.scrollHeight,
        top: 0,
        left: 0,
        background: boxPopUpOpen ? 'rgba(255, 255, 255, 0.65)' : 'transparent',
        backdropFilter: boxPopUpOpen ? 'blur(0.5rem)' : 'none',
        transition: 'all 1s ease-in-out',
        zIndex: boxPopUpOpen ? 2147483649 : -1000,
      }}
    />
  );
};
