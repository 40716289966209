/** @jsx jsx */
import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, jsx } from 'theme-ui';

import BagIcon from '~/assets/images/August-Cart.svg';
import CartContext from '~/contexts/CartContext';

const NavMenuDesktopCartLink = ({ itemCount }) => {
  const [showItemCount, setShowItemCount] = useState(false);
  const [, setIsCartOpen] = useContext(CartContext);

  useEffect(() => {
    setShowItemCount(typeof itemCount === 'number');
  }, [itemCount]);

  return (
    <IconButton
      onClick={() => setIsCartOpen(true)}
      key="cart"
      aria-label="Open cart"
      sx={{
        '&:focus': { outline: 'solid blue' },
        position: 'relative',
        color: 'inherit',
        opacity: showItemCount ? 1 : 0,
        variant: 'text.navLink',
        height: ['5.5rem'],
        width: ['5.5rem'],
        padding: 0,
        cursor: "url('/images/blood.svg'), pointer",
        outline: 'none',
      }}
    >
      <BagIcon sx={{ height: '100%' }} />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -30%)',
          color: '#fff',
          fontSize: '1.2rem',
          fontWeight: 700,
        }}
      >
        {itemCount || 0}
      </Box>
    </IconButton>
  );
};

NavMenuDesktopCartLink.propTypes = {
  itemCount: PropTypes.number,
};

export default NavMenuDesktopCartLink;
