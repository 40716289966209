/** @jsx jsx */
import { Box, Text, jsx, Flex } from 'theme-ui';
import { utils } from '@chordcommerce/gatsby-theme-autonomy';

const ProgressBar = ({ total }) => {
  const { toUsdCurrency } = utils;
  const freeShipThreshold = process.env.GATSBY_FREE_SHIPPING_THRESHOLD || 0;
  const progress = Math.min((total / freeShipThreshold) * 100, 100);
  const final =
    progress === 0
      ? toUsdCurrency(freeShipThreshold)
      : toUsdCurrency(Math.ceil(freeShipThreshold - total));

  let freeShipMessage;

  if (progress === 0) {
    freeShipMessage = `You’re only ${final} away from free shipping!`;
  } else if (progress > 0 && progress <= 50) {
    freeShipMessage = `Getting closer! Only ${final} away from free shipping!`;
  } else if (progress > 50 && progress < 100) {
    freeShipMessage = `Almost there! Only ${final} away from free shipping!`;
  } else {
    freeShipMessage = 'Congrats!! You qualify for free shipping! ';
  }

  return (
    <Flex sx={{ flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          height: '2.75rem',
          border: '1px solid #CF2029',
          backgroundColor: '#CF202999',
          borderRadius: '2.5rem',
          mb: '0.75rem',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#CF2029',
            height: '100%',
            width: `${progress}%`,
            transition: 'width 1s ease-in-out',
            m: 0,
            borderRadius: '2.5rem 0 0 2.5rem',
            position: 'absolute',
          }}
        />
        <Flex
          sx={{
            alignItems: 'center',
            height: '2.5rem',
            position: 'absolute',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Text
            sx={{
              fontWeight: 700,
              fontSize: ['1.2rem', '1.2rem', '1.2rem'],
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {freeShipMessage}
            <Text />
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ProgressBar;
