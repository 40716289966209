import { useLayoutEffect } from 'react';

const useLockBodyScroll = (condition) => {
  useLayoutEffect(() => {
    const thatDoc = typeof document !== 'undefined' ? document : undefined;
    if (condition) {
      thatDoc.body.style.overflow = 'hidden';
    } else {
      thatDoc.body.style.overflow = 'auto';
    }
    // Re-enable scrolling when component unmounts
    return () => (thatDoc.body.style.overflow = 'auto');
  }, [condition]); // Empty array ensures effect is only run on mount and unmount
};

export default useLockBodyScroll;
