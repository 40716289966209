import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Image, Text } from 'theme-ui';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import ArrowPrev from '~/assets/images/icons/arrow-prev.svg';
import ArrowNext from '~/assets/images/icons/arrow-next.svg';

const TopOfferBanner = () => {
  const sliderRef = useRef();
  const [autoplaySpeed, setAutoplaySpeed] = useState(2000);
  const [activeBanners, setActiveBaners] = useState([]);
  const { data } = useStaticQuery(graphql`
    query {
      data: contentfulTopOfferBanner(title: { eq: "Global Banner" }) {
        bannersList {
          bannerCopy
          endTime
          startTime
          targetLink
          trailingSvg {
            title
            url
          }
          isHidden
        }
      }
    }
  `);

  useEffect(() => {
    const banners = data.bannersList.filter((item) => {
      return (
        new Date(item.startTime).getTime() < new Date().getTime() &&
        new Date(item.endTime).getTime() > new Date().getTime() &&
        !item.isHidden
      );
    });
    setActiveBaners(banners);
  }, []);

  const handleAfterChange = (current) => {
    // First Slide ususaly the new Promo or News stays longer than the others.
    if (current === 0) {
      setAutoplaySpeed(12000);
    } else {
      setAutoplaySpeed(8000);
    }
  };

  return activeBanners ? (
    <Box
      sx={{
        backgroundColor: 'alizarinCrimson',
        textAlign: 'center',
        position: 'relative',
        '.slick-slider': {
          overflowX: 'hidden',
        },
        '.slick-track': {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <Slider
        ref={sliderRef}
        afterChange={handleAfterChange}
        arrows={false}
        infinite
        speed={750}
        autoplay
        cssEase="ease-in-out"
        autoplaySpeed={autoplaySpeed}
      >
        {activeBanners.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                padding: ['1.25rem 6rem', '1.25rem 6rem', '2rem 6rem'],
                width: '100%',
                maxWidth: ['55ch', '55ch', '80ch'],
                img: {
                  maxWidth: '3rem',
                  maxHeight: '5rem',
                  ml: '0.5rem',
                  display: 'inline',
                },
              }}
            >
              <Text
                as={Link}
                to={item?.targetLink}
                sx={{
                  color: 'canary',
                  fontSize: [4, 6, 9],
                  textAlign: 'center',
                  verticalAlign: 'super',
                }}
              >
                {item.bannerCopy}
              </Text>
              <Image src={item.trailingSvg?.url} alt={item?.trailingSvg?.title} />
            </Box>
          );
        })}
      </Slider>
      <Box
        sx={{
          svg: {
            width: ['1rem', '1.15rem', '1.25rem'],
          },
          '& path': {
            stroke: 'canary',
            strokeWidth: '0.5rem',
          },
        }}
      >
        <Button
          onClick={() => sliderRef.current.slickPrev()}
          sx={{
            border: 'none',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(0,-50%)',
            height: '100%',
            width: ['10%', '7%', '5%'],
            backgroundColor: '#CF2029',
          }}
        >
          <ArrowPrev />
        </Button>
        <Button
          onClick={() => sliderRef.current.slickNext()}
          sx={{
            border: 'none',
            // px: '0.5rem !important',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate(0,-50%)',
            height: '100%',
            width: ['10%', '7%', '5%'],
            backgroundColor: '#CF2029',
          }}
        >
          <ArrowNext />
        </Button>
      </Box>
    </Box>
  ) : null;
};

export default TopOfferBanner;
