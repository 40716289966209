const updateCreatorIQMetric = async () => {
  if (localStorage.creatorIQid) {
    const trackingLinkId = localStorage.creatorIQid;

    try {
      await fetch('/.netlify/functions/postCIQMetric', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ trackingLinkId }),
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export default updateCreatorIQMetric;
