/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useContext, useLayoutEffect, useState } from 'react';
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { BoxBuilderBanner } from './BoxBuilderBanner';
import { BuildABoxModal } from './BuildABoxModal';
import { BoxBuilderIndicator } from './BoxBuilderIndicator';
import BoxBuilderPopUpContext from '~/contexts/BoxBuilderPopUpContext';

const BuildABoxWidget = ({ hideWidget }) => {
  const data = useStaticQuery(graphql`
    query {
      product: contentfulProduct(slug: { eq: "box-builder" }) {
        ...ProductPageFragment
        subPromoMessage
        nonSubPromoMessage
        widgetPromoEndTime
        widgetPromoStartTime
        promoImage {
          gatsbyImageData(quality: 100)
          file {
            url
          }
        }
      }
    }
  `);

  const { product } = data;
  const [pageLoaded, setPageLoaded] = useState(false);
  const { buttonIsLoading } = useContext(BoxBuilderPopUpContext);

  useLayoutEffect(() => {
    setPageLoaded(true);
  }, []);

  return pageLoaded ? (
    <>
      {!hideWidget && (
        <>
          <BoxBuilderBanner />
          {!buttonIsLoading && <BoxBuilderIndicator />}
        </>
      )}
      <BuildABoxModal product={product} />
    </>
  ) : null;
};

export default BuildABoxWidget;
