/** @jsx jsx */
import { GatsbyImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { Box, Flex, jsx, Text } from 'theme-ui';

import DownChevronTan from '~/assets/images/icons/DownChevronTan.svg';

const BABWidgetChevron = ({ title, variantTotal = 0, children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => setCollapsed(!collapsed);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        color: 'darkTan',
        '> table,td': {
          width: '100%',
        },
        '> table, td, th': {
          border: `1px solid #e3232a`,
          p: '1.5rem',
          textAlign: 'center',
        },
      }}
    >
      <Flex
        onClick={toggleCollapse}
        sx={{
          textAlign: 'left',
          alignItems: 'center',
          backgroundColor: 'rgba(180, 175, 192, 0.25)',
          borderColor: 'chatelle',
          borderWidth: '0.1rem',
          borderStyle: 'solid',
          px: '1.5rem',
          py: '2rem',
          height: '6rem',
          justifyContent: 'space-between',
          cursor: "url('/images/blood.svg'), auto",
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Flex sx={{ alignItems: 'center', width: '70%' }}>
          <Flex
            sx={{
              borderRadius: '2.5rem',
              backgroundColor: 'darkTan',
              height: '4rem',
              width: '4rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text sx={{ color: '#fff', mt: '-0.25rem' }}>{variantTotal}</Text>
          </Flex>
          <Text
            sx={{
              ml: '1rem',
              width: '75%',
              textAlign: 'left',
              fontSize: '1.4rem',
              fontWeight: 700,
              letterSpacing: '0.25px',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Text>
        </Flex>
        <DownChevronTan
          sx={{
            transform: collapsed ? 'rotate(180deg)' : 'none',
            transition: 'transform 250ms ease-in-out',
            width: ['2rem', '3rem'],
          }}
        />
      </Flex>
      <Flex
        data-testid="collapsible-content"
        sx={{
          height: collapsed ? 0 : 'auto',
          transition: 'all 2500ms ease-in',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default BABWidgetChevron;
