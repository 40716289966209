/** @jsx jsx */
import { useAnalytics, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Text, Input, Label, jsx } from 'theme-ui';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import { trackEmailCaptureEvent } from '~/utils/analytics';

const FormInputWithLabel = React.forwardRef(({ name, label, ...props }, ref) => (
  <Box sx={{ position: 'relative', width: '100%' }}>
    <Input
      sx={{
        variant: 'text.link',
        width: 'calc(100% - 4rem)',
        fontSize: [6],
        color: 'canary',
        padding: ['1.6rem 2rem', '1.6rem 2rem', '1.6rem 2rem'],
        border: 'none',
        '&::placeholder': {
          color: 'canary',
          opacity: 1,
        },
        '&:focus': { outline: 'none' },
        ':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
          {
            '-webkit-text-fill-color': '#e6fc5c',
            '-webkit-box-shadow': '0 0 0 30px #CF2029 inset !important',
          },
        '&:autofill:first-line, &:autofill:hover:first-line, &:autofill:focus:first-line': {
          fontFamily: 'primary',
          fontSize: [6],
        },
      }}
      name={name}
      ref={ref}
      {...props}
    />

    <ArrowRight
      sx={{
        position: 'absolute',
        top: '50%',
        right: '2rem',
        width: '2rem',
        transform: 'translateY(-50%)',
        path: { stroke: 'canary' },
      }}
    />

    <Label
      htmlFor={name}
      sx={{
        position: 'absolute',
        top: ['8px', '15px', '12px'],
        left: '15px',
        opacity: '0',
        pointerEvents: 'none',
      }}
    >
      {label}
    </Label>
  </Box>
));

const EmailCapture = () => {
  const { trackEmailCaptured } = useAnalytics();
  const translate = useTranslate();
  const { register, handleSubmit, errors } = useForm();
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const { email } = data;

    try {
      trackEmailCaptured({
        emailCapture: {
          email,
          page: 'all-pages',
          component: 'footer',
          website: window.location.hostname,
        },
      });

      trackEmailCaptureEvent(email, 'footer');
      await fetch('/.netlify/functions/captureEmail', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err, { source: 'FooterNewsletterSignup' });
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '34.6rem' }}>
      {!submitted && (
        <Flex
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            margin: [null, 'auto'],
            border: '3px solid transparent',
            borderColor: 'canary',
            borderRadius: '10px',
            width: '100%',
          }}
        >
          <FormInputWithLabel
            name="email"
            title={translate('footer.newsletter_signup.input_label')}
            label={translate('footer.newsletter_signup.input_label')}
            placeholder="DROP YOUR EMAIL"
            aria-label={translate('footer.newsletter_signup.input_label')}
            ref={register({
              required: true,
              pattern: {
                value: /^\S+@\S+$/i,
                message: translate('validation.invalid_email'),
              },
            })}
          />
          {errors && errors.email && (
            <Text
              variant="formError"
              sx={{
                backgroundColor: 'errorLight',
                padding: '12px 12px',
                order: [null, null, 3],
                marginBottom: ['0.75rem', null, '0'],
              }}
            >
              {errors.email.type === 'required' && translate('validation.required_field')}

              {errors.email.type === 'pattern' && translate('validation.invalid_email')}
            </Text>
          )}
        </Flex>
      )}

      {submitted && (
        <Box
          sx={{
            margin: [null, 'auto'],
            maxWidth: [null, '295px', 'none'],
            textAlign: ['center', null, 'left'],
            color: 'canary',
            fontSize: [3, 5],
          }}
        >
          {translate('footer.newsletter_signup.confirmation')}
        </Box>
      )}
    </Box>
  );
};

export default EmailCapture;
