import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';

import QuantitySelector from '~/components/Generic/QuantitySelector';

const CartQuantitySelector = ({
  boxItem,
  quantity,
  fourPack,
  twelvePack,
  twentyFourPack,
  lineItemId,
  isFetching,
  isUpdating,
  setIsUpdating,
  totalOnHand = 1,
  hideRemove,
  sku,
  name = '',
  ...props
}) => {
  const { modifyLineItem, removeFromCart } = useCart();
  const [boxItemOptions, setBoxItemOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const maxQuantity = Math.min(totalOnHand, Math.max(quantity, 20));
  const selectorQuantity = twentyFourPack ? 4 : twelvePack ? 8 : fourPack ? 24 : 12;
  const selectorQuantityArray = Array.from(Array(selectorQuantity).keys());
  const baseArray = hideRemove ? [] : [{ name: '0 - Remove', value: 0 }];
  const selectionMultiplier =
    name?.toLowerCase().includes('tampon') || name?.toLowerCase().includes('liner');
  const boxQuantityOptionsArray = selectionMultiplier
    ? selectorQuantityArray.filter((a, i) => i % 2 === 0).map((a) => a + 2)
    : selectorQuantityArray.map((a) => a + 1);
  const isIncrementB = process.env.GATSBY_TEST_B === 'true';
  const finalBoxItemArray = isIncrementB
    ? boxQuantityOptionsArray.map((a) => {
        return {
          name: a.toString(),
          value: a,
        };
      })
    : [...Array(selectorQuantity)].map((_, i) => ({
        name: (i + 1).toString(),
        value: i + 1,
      }));
  const options = baseArray.concat(
    [...Array(maxQuantity)].map((_, i) => ({
      name: (i + 1).toString(),
      value: i + 1,
    }))
  );
  useEffect(() => {
    if (boxItem) {
      setBoxItemOptions([{ name: '0 - Remove', value: 0 }].concat(finalBoxItemArray));
    }
    return () => {
      setBoxItemOptions(null);
    };
  }, [boxItem]);

  const updateQuantity = async (quan) => {
    setIsLoading(true);
    if (setIsUpdating) setIsUpdating(true);
    const intQuantity = parseInt(quan);
    try {
      if (intQuantity === 0) {
        await removeFromCart({ lineItemId });
      } else {
        await modifyLineItem({ lineItemId, attributes: { quantity: intQuantity } });
      }
      setIsLoading(false);
      if (setIsUpdating) setIsUpdating(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (setIsUpdating) setIsUpdating(false);
    }
  };

  useEffect(() => setIsLoading(false), [quantity, lineItemId]);

  return (
    <QuantitySelector
      defaultValue={quantity}
      isLoading={isLoading}
      isUpdating={isUpdating}
      handleChange={updateQuantity}
      options={boxItem ? boxItemOptions : options}
      {...props}
    />
  );
};

CartQuantitySelector.propTypes = {
  lineItemId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default CartQuantitySelector;
