/** @jsx jsx */
import { Flex, Text, Box, jsx, useThemeUI, Checkbox, Label, Spinner } from 'theme-ui';
import _ from 'lodash';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { useContext, useEffect, useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import LineItem from '../LineItem';
import BoxItemsContainer from '../BoxItemsContainer';
import PromoCode from '../PromoCode';
import { DividerLine } from '~/components/Generic/DividerLine/DividerLine';
import Spacer from '~/components/Generic/Layout/Spacer';
import Delete from '~/assets/images/icons/Delete.svg';
import BoxBuilderPopUpContext from '~/contexts/BoxBuilderPopUpContext';
import CartAddons from '../AddOns';
import DonateABox from '../DonateABox';

const RowWrapper = ({ children }) => (
  <Flex
    sx={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      mb: '1.5rem',
      fontSize: '13px',
      textTransform: 'uppercase',
      fontWeight: 700,
    }}
  >
    {children}
  </Flex>
);

const LineItems = ({
  items = [],
  orderConfirmed = false,
  removeFromCart,
  itemTotal,
  displayItemTotal,
  displayTaxTotal,
  displayShipTotal,
  promotions,
  eligibleForFreeShipping,
  displayTotal,
  total,
  estimatedTotal,
  handleTCCheck,
  setOutOfStock,
  ...props
}) => {
  const {
    data: { intervals: intervalOptions },
  } = useStaticQuery(graphql`
    query intervalQuery {
      data: contentfulSubscription {
        intervals {
          id
          name
          slug
          presentation
          length
          unit
        }
      }
    }
  `);
  const promoCode = promotions ? _.filter(promotions, (p) => p.code) : [];
  const displayPromo = promotions?.filter(
    (p) =>
      (p.code && !p.label?.toLowerCase().includes('shipping')) ||
      p.label?.toLowerCase().includes('referral')
  );
  const { theme } = useThemeUI();
  const { cart, isFetching, removePromoCode } = useCart();

  const [donationExists, setDonationExists] = useState(false);
  const [boxTotalPrice, setBoxTotalPrice] = useState(0);
  const [shippingPopupVisible, setShippingPopupVisible] = useState(false);
  const [donationPromoMessage, setDonationPromoMessage] = useState('');
  const { isReplacingBox } = useContext(BoxBuilderPopUpContext);

  const removePromo = async (code) => {
    await removePromoCode({ promoCode: code });
  };

  useEffect(() => {
    setDonationExists(cart.lineItems?.some((item) => item.variant?.slug.includes('donation')));
  }, [cart]);

  // All SKU Arrays for filtering
  const boxSkus = ['box-builder', 'tampon-box', 'liners-box', 'pad-box', 'sample-box'];
  const boxSubItemSkus = [
    'pad-night',
    'pad-day',
    'pad-ngt-12',
    'pad-day-12',
    'tampon-light',
    'tampon-regular',
    'tampon-super',
    'liners-original',
    'pad-lnr-24',
    'pad-lng-ngt-12',
  ];
  const nonBoxSubscriptionSKUs = ['travel-20ct', 'august-sample-longnight', 'august-sample'];
  const allBoxItemSkus = boxSkus.concat(boxSubItemSkus);

  // filtering Build a box and other subscribable lineitems to calculate pricing and discount manually

  // Build a Box
  const mainBoxItems = items.filter((item) => boxSkus.includes(item.variant.sku));

  // nonBoxSubscriptionSKUs to check each one of them and filter those that have subscription
  // use that list to calculate the discount
  const nonBoxSubLineItmes = items.filter((item) =>
    nonBoxSubscriptionSKUs.includes(item.variant.sku)
  );
  const nonBoxLineItemsWithSub = nonBoxSubLineItmes.filter(
    (item) => item.subscriptionLineItems?.length > 0
  );

  // Only calculate the display discount amount from those who acutually have subscription.
  const nonBoxSubTotalPrice = nonBoxLineItemsWithSub
    ?.map((item) => parseFloat(item.price) * item.quantity)
    ?.reduce((a, b) => {
      return a + b;
    }, 0);

  // Items that are not subscribable at all Merch and other non period care products
  const NonSubscriptionItems = items.filter(
    (item) =>
      !allBoxItemSkus.includes(item.variant.sku) &&
      !nonBoxSubscriptionSKUs.includes(item.variant.sku) &&
      (!['donation-box-5'].includes(item.variant.sku) || orderConfirmed)
  );

  // Check if the build a box is a subscription
  const isMainBoxASubscription = mainBoxItems[0]?.subscriptionLineItems?.length > 0;

  // Check if there is any subscription in cart
  const isSubscription = items.find((item) => item.subscriptionLineItems?.length > 0);

  // Calculate if there is a subscription based discount in cart
  // This is done by checking isMainBoxASubscription and nonBoxSubTotalPrice
  const subDiscount =
    Number(boxTotalPrice * (isMainBoxASubscription ? 0.1 : 0)) + Number(nonBoxSubTotalPrice) * 0.1;
  const freeItemPromos = NonSubscriptionItems.filter((li) =>
    li.adjustments?.find((promo) => !!promo.label?.includes('Free'))
  );

  const donationBoxInCart = cart?.lineItems?.find((item) => item.variant.sku === 'donation-box-5');

  return (
    <Flex
      id="ordConfItems"
      sx={{
        alignSelf: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: orderConfirmed ? ['none', 'none', 'auto'] : ['auto'],
        py: orderConfirmed ? 0 : '2rem',
        height: orderConfirmed ? 'auto' : '100vh',
        width: '100%',
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          borderBottom: orderConfirmed ? 'none' : '1px solid canary',
        }}
      >
        {isReplacingBox ? (
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '2rem',
            }}
          >
            <Spinner size="50" color="alizarinCrimson" />
          </Flex>
        ) : mainBoxItems ? (
          mainBoxItems.map((item, idx) => (
            <BoxItemsContainer
              items={items}
              promotions={promotions}
              orderConfirmed={orderConfirmed}
              removeFromCart={removeFromCart}
              mainBoxItem={item}
              sku={item.variant.sku}
              key={`${item.variant.sku}-${idx}`}
              isFetching={isFetching}
              boxTotalPrice={boxTotalPrice}
              setBoxTotalPrice={setBoxTotalPrice}
              intervalOptions={intervalOptions}
            />
          ))
        ) : null}

        {_.map(nonBoxSubLineItmes, (li, idx) => (
          <LineItem
            key={li.id}
            id={li.id}
            lineItem={li}
            variant={li.variant}
            itemPromos={li.adjustments.find((promo) => !!promo.label?.includes('Free'))}
            sku={li.variant && li.variant.sku}
            slug={li.variant && li.variant.slug}
            name={li.variant && li.variant.name}
            options={li.variant && li.variant.optionsText}
            quantity={li.quantity}
            displayAmount={li.displayAmount}
            singleDisplayAmount={li.singleDisplayAmount}
            orderConfirmed={orderConfirmed}
            removeFromCart={removeFromCart}
            setOutOfStock={setOutOfStock}
            giftCards={li.giftCards}
            idx={idx}
            NonSubscriptionItems={NonSubscriptionItems}
            intervalOptions={intervalOptions}
          />
        ))}
        {_.map(NonSubscriptionItems, (li, idx) => (
          <LineItem
            key={li.id}
            id={li.id}
            lineItem={li}
            variant={li.variant}
            itemPromos={li.adjustments.find((promo) => !!promo.label?.includes('Free'))}
            sku={li.variant && li.variant.sku}
            slug={li.variant && li.variant.slug}
            name={li.variant && li.variant.name}
            options={li.variant && li.variant.optionsText}
            quantity={li.quantity}
            displayAmount={li.displayAmount}
            singleDisplayAmount={li.singleDisplayAmount}
            orderConfirmed={orderConfirmed}
            removeFromCart={removeFromCart}
            setOutOfStock={setOutOfStock}
            giftCards={li.giftCards}
            idx={idx}
            NonSubscriptionItems={NonSubscriptionItems}
            intervalOptions={intervalOptions}
          />
        ))}
      </Flex>
      {!orderConfirmed && (
        <Box sx={{ px: '2rem', pb: '3rem' }}>
          <Spacer height="0.5rem" />
          <DividerLine height="0.1rem" color="chatelle" />

          <Text
            sx={{
              fontSize: '1.25rem',
              fontStyle: 'italic',
              fontWeight: 600,
            }}
          >
            {donationPromoMessage}
          </Text>
          <DividerLine height="0.1rem" color="chatelle" />
          <Spacer height="0.5rem" />

          <CartAddons itemsInCart={items} />

          <DonateABox
            donationBoxInCart={donationBoxInCart}
            orderConfirmed={orderConfirmed}
            removeFromCart={removeFromCart}
            setOutOfStock={setOutOfStock}
            NonSubscriptionItems={NonSubscriptionItems}
            intervalOptions={intervalOptions}
            setDonationPromoMessage={setDonationPromoMessage}
          />

          <Spacer height="0.5rem" />
          <DividerLine height="0.1rem" color="chatelle" />
          <Spacer height="0.5rem" />
          <Box>
            <PromoCode promotion={promoCode} />
            <Box
              sx={{
                py: '1.5rem',
                color: ' ',
              }}
            >
              {displayItemTotal && (
                <RowWrapper>
                  <Text>Subtotal</Text>
                  {!isFetching && (
                    <Flex>
                      <Text sx={{ textDecoration: isSubscription ? 'line-through' : 'none' }}>
                        ${Number(itemTotal).toFixed(2)}
                      </Text>
                      {isSubscription ? (
                        <Text sx={{ color: 'discountGreen', ml: '0.5rem' }}>
                          ${Number(itemTotal - subDiscount).toFixed(2)}
                        </Text>
                      ) : null}
                    </Flex>
                  )}
                </RowWrapper>
              )}
              {!!displayPromo?.length &&
                displayPromo.map((promo) => {
                  const promoName = promo.code ? promo.code : 'Referral';
                  // Convert 0 decimal currency strings to 2 decimal
                  const promoAmount =
                    promoName === 'Referral'
                      ? '-$5.00'
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(promo.displayAmount.replace(/[^0-9.-]+/g, ''));
                  return (
                    <RowWrapper>
                      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Text>{promoName}</Text>
                        {promo.code && (
                          <Delete
                            sx={{
                              cursor: "url('/images/blood.svg'), pointer",
                              ml: '0.5rem',
                              width: '2rem',
                              maxHeight: '2rem',
                              path: {
                                stroke: 'alzarineCrimson',
                                fill: '#CF2029',
                              },
                            }}
                            onClick={() => {
                              removePromo(promo.code);
                            }}
                          />
                        )}
                      </Flex>
                      <Text sx={{ color: 'discountGreen' }}>{promoAmount}</Text>
                    </RowWrapper>
                  );
                })}
              {!!freeItemPromos.length &&
                freeItemPromos.map((item) => {
                  const promo = item.adjustments[0];
                  // if free item promo is based on a code that code will show up in reg promocodes so dont show this custom promo message
                  const freeItemIsCodeBased = item.adjustments.find(
                    (adjust) => !adjust?.label?.includes('Free')
                  );
                  const promoName = promo?.label?.split('-')[1] || 'Discount';
                  const promoAmount = promo.displayAmount;
                  return !freeItemIsCodeBased ? (
                    <RowWrapper>
                      <Text>{promoName}</Text>
                      <Text sx={{ color: 'discountGreen' }}>{promoAmount}</Text>
                    </RowWrapper>
                  ) : null;
                })}
              {displayShipTotal && (
                <RowWrapper>
                  <Text>
                    Shipping
                    <Box
                      sx={{
                        backgroundColor: 'chatelle',
                        padding: '0.25rem',
                        borderRadius: 25,
                        height: '2rem',
                        width: '2rem',
                        display: eligibleForFreeShipping ? 'none' : 'inline-block',
                        textAlign: 'center',
                        ml: '1rem',
                        pointer: 'cursor',
                      }}
                      onClick={() => {
                        setShippingPopupVisible((prev) => !prev);
                        setTimeout(() => {
                          setShippingPopupVisible(false);
                        }, 4000);
                      }}
                    >
                      ?
                    </Box>
                  </Text>
                  <Text
                    sx={{ color: eligibleForFreeShipping ? 'discountGreen' : 'alizarinCrimson' }}
                  >
                    {eligibleForFreeShipping ? 'Free' : '$6.99'}
                  </Text>
                </RowWrapper>
              )}
              <Box
                sx={{
                  display: shippingPopupVisible ? 'flex' : 'none',
                  width: '100%',
                  py: '1rem',
                  mb: '1.5rem',
                  color: 'canary',
                  border: `1px solid ${theme.colors.canary}`,
                  borderRadius: '3px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'alizarinCrimson',
                  zIndex: '5',
                  fontSize: ['11px'],
                  opacity: shippingPopupVisible ? 1 : 0,
                  visibility: shippingPopupVisible ? 'visible' : 'hidden',
                  transition: 'opacity 0.25s ease, visibility 0.25s ease',
                }}
              >
                Don't forget - free shipping on all orders over $50!
              </Box>
              <RowWrapper>
                <Text>EST. Total</Text>
                <Text sx={{ color: 'discountGreen' }}>${estimatedTotal.toFixed(2)}</Text>
              </RowWrapper>

              {cart.displayTotalAvailableStoreCredit !== '$0.00' && (
                <RowWrapper>
                  <Text>
                    Store Credit
                    <br />
                    <Text as="span" sx={{ fontSize: [1], textTransform: 'none' }}>
                      (will be applied at checkout)
                    </Text>
                  </Text>
                  <Text>{cart.displayTotalAvailableStoreCredit}</Text>
                </RowWrapper>
              )}
            </Box>
            <Flex sx={{ mb: '3rem' }} id="tcCheckboxRef">
              <Label sx={{ width: 'auto', marginRight: ['2rem', '1rem'] }}>
                <Checkbox
                  color="alizarinCrimson"
                  defaultChecked={false}
                  sx={{
                    'input:checked ~ &': {
                      color: 'alizarinCrimson',
                    },
                    'input:focus ~ &': {
                      color: 'alizarinCrimson',
                      bg: 'transparent',
                    },
                  }}
                  onChange={handleTCCheck}
                />
              </Label>
              <Text>
                Check here to indicate that you have read and agree to August's{'  '}
                <Link to="/terms" sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                  terms & conditions
                </Link>
              </Text>
            </Flex>
          </Box>
        </Box>
      )}
      <Spacer height="3rem" />
    </Flex>
  );
};

export default LineItems;
