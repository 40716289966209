/** @jsx jsx */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Flex, IconButton, jsx, Spinner, Text } from 'theme-ui';
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { navigate } from 'gatsby';
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import { useIsLoggedIn } from '~/utils/account/useIsLoggedIn';
import { GlowlabsPromos } from '~/utils/misc/glowlabsPromos';
import CartContext from '~/contexts/CartContext';

const PromoCode = ({ promotion }) => {
  const translate = useTranslate();
  const { addPromoCode, promoCode: pc } = useCart();
  const [promoLength, setPromoLength] = useState(promotion.length);
  const [, setCartIsOpen] = useContext(CartContext);

  const { isFetching } = pc;
  const [apiError, setApiError] = useState(null);
  const [isFormActive, setIsFormActive] = useState(true);

  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      promoCode: promotion[0]?.code || '',
    },
  });
  const logInPrompt = 'Please log in to redeem this coupon.';

  useEffect(() => {
    reset({ promoCode: '' });
    setPromoLength(promotion.length);
  }, [promotion.length]);

  useEffect(() => {
    // promo length didnt change but the promotion object changed which means that a promo was replaced by another promo
    // Due to those not being always aplied.
    // this check is to show the user an error for that
    if (promotion.length === promoLength && promoLength) {
      setApiError({
        message: 'Previous promo replaced. Only one site-wide promo can be applied at a time.',
      });
      setTimeout(() => {
        reset({ promoCode: '' });
        setApiError(null);
      }, 5000);
    }
  }, [JSON.stringify(promotion)]);

  const { isLoggedIn } = useIsLoggedIn();

  const onSubmit = async (data) => {
    if (isFetching) return;
    if (GlowlabsPromos.includes(data.promoCode) && !isLoggedIn) {
      reset({ promoCode: '' });
      setApiError({ message: logInPrompt });
      return;
    }
    try {
      setApiError(null);
      await addPromoCode({ promoCode: data.promoCode });
    } catch (error) {
      if (GlowlabsPromos.includes(data.promoCode)) {
        if (error.message.includes('once per user')) {
          setApiError({ message: 'This coupon has already been redeemed.' });
        } else {
          setApiError({
            message:
              'You are not eligible for this coupon. Try logging in with a different email address.',
          });
        }
      } else {
        setApiError(error);
      }
      reset({ promoCode: '' });
    }
  };

  const activatePromoCodeForm = (event) => {
    if (event) event.preventDefault();
    setIsFormActive(true);
  };

  const handleRedirect = () => {
    navigate('/account/signin');
    setCartIsOpen(false);
  };

  if (!isFormActive && !promotion) {
    return (
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Box as="form" onSubmit={(e) => activatePromoCodeForm(e)}>
          <Button
            sx={{
              border: 'none',
              padding: 0,
              fontWeight: 700,
              fontSize: '12px',
              textTransform: 'uppercase',
              p: '10px',
              color: 'canary',
              '&:hover': { backgroundColor: 'canary', color: 'alizarinCrimson' },
            }}
          >
            + Apply promo
          </Button>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex sx={{ flexDirection: 'column', my: '1rem' }}>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              input: {
                border: '1px solid',
                borderColor: 'alizarinCrimson',
                background: 'white',
                px: '1rem !important',
                py: '0.5rem !important',
                m: 0,
                height: '42px',
                borderRadius: '0.5rem 0 0 0.5rem ',
                fontFamily: 'halcom !important',
                fontSize: '12px',
                fontWeight: 700,
                '::placeholder': {
                  textTransform: 'uppercase',
                  color: '#B4AFC0',
                  fontFamily: 'halcom',
                },
              },
            }}
          >
            <FormInputWithLabel
              hideLabel
              aria-label={translate('cart.promo_code')}
              placeholder="Promo Code"
              name="promoCode"
              ref={register({ required: !promotion })}
            />
          </Box>
          <Flex
            sx={{
              width: '4.5rem',
              height: '100%',
              backgroundColor: 'alizarinCrimson',
              justifyContent: 'center',
              alignItems: 'cneter',
              px: '1rem ',
              py: '0.5rem ',
              borderRadius: '0 0.5rem 0.5rem 0',
            }}
          >
            <IconButton
              sx={{
                p: '0 !important',
                cursor: "url('/images/blood.svg'), pointer",
                transform: 'rotate(-45deg)',
                width: '20px',
              }}
              disabled={isFetching}
            >
              {isFetching ? (
                <Spinner size="15" color="#fff" />
              ) : (
                <ArrowRight sx={{ path: { stroke: '#fff' } }} />
              )}
            </IconButton>
          </Flex>
        </Flex>
      </Box>
      {apiError && (
        <Text
          onClick={() => {
            apiError?.message === logInPrompt && handleRedirect();
          }}
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            mt: '10px',
            fontSize: ['11px', '13px'],
            border: 'none',
            textAlign: 'left',
            p: 0,
            cursor: apiError?.message === logInPrompt && "url('/images/blood.svg'), pointer",
            textDecoration: apiError?.message === logInPrompt && 'underline',
          }}
        >
          {apiError.message}
        </Text>
      )}
      {errors.promoCode && (
        <Text
          variant="formError"
          sx={{
            color: 'errorDark',
            backgroundColor: 'errorLight',
            mt: '10px',
            fontSize: ['11px', '13px'],
          }}
        >
          {translate('validation.required_field')}
        </Text>
      )}
    </Flex>
  );
};

PromoCode.propTypes = {
  promotion: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    amount: PropTypes.number,
    displayAmount: PropTypes.string,
  }),
};

export default PromoCode;
