/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { useCart, useCheckout, useTranslate } from '@chordcommerce/gatsby-theme-autonomy';
import { Button, Text, Spinner, jsx } from 'theme-ui';
import getStripe from '~/utils/misc/getStripe';
import MessageContext from '~/contexts/MessageContext';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import { trackCustomEvent } from '~/utils/analytics';
import { getAllFormatedPromotions } from '~/utils/promotions/formatPromotions';
import { getallStoredTags } from '~/utils/misc/storeTags';

const CheckoutButton = ({ tCChecked, outOfStock, estimatedTotal }) => {
  const { prepareCheckout } = useCheckout();
  const { cart: userCart } = useCart();
  const translate = useTranslate();
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [shareasaleTrack, setShareasaleTrack] = useState(false);
  const [, setMessage] = useContext(MessageContext);
  const { didAddToCart } = useBoxBuilder();

  const boxSubItemSkus = [
    'tampon-light',
    'tampon-regular',
    'tampon-super',
    'pad-day',
    'pad-night',
    'pad-lng-ngt-12',
    'liners-original',
    'pad-day-12',
    'pad-ngt-12',
    'pad-lnr-24',
  ];

  const boxInCart = userCart.lineItems.find((item) => item.variant.slug === 'box-builder');
  const boxItemsQuantityInCart = userCart.lineItems
    .filter((item) => boxSubItemSkus.includes(item.variant.sku))
    .reduce((a, c) => {
      a += Number(c.variant.name.replace(/\D/g, '')) * c.quantity;
      return a;
    }, 0);

  const nonBoxItemsInCart = userCart.lineItems.filter(
    (item) => !boxSubItemSkus.includes(item.variant.sku) && item.variant.slug !== 'box-builder'
  );

  const stockError = Object.values(outOfStock).indexOf(true) > -1;
  const handleSubmit = async (event) => {
    if (event) event.preventDefault();

    const isIncrementB = process.env.GATSBY_TEST_B === 'true';

    setButtonIsLoading(true);
    try {
      if (boxInCart && boxItemsQuantityInCart < 20) {
        throw new Error();
      }
      if (stockError) {
        throw new Error();
      }
      if (!tCChecked) {
        const checkBox = document.getElementById('tcCheckboxRef');
        checkBox.scrollIntoView({ behavior: 'smooth' });
        throw new Error();
      }
      setShareasaleTrack(true);
      const cart = await prepareCheckout();
      setTimeout(() => {
        setShareasaleTrack(false);
      }, 1000);

      const stripe = await getStripe();

      if (didAddToCart)
        trackCustomEvent('Box Builder Lead to Checkout', { location: window.location.pathname });
      const storedTags = getallStoredTags();
      if (storedTags) {
        const formattedTags = Object.entries(storedTags).map((item) => {
          const tag = `${item[1].tag}${item[1]?.edited ? '_edited' : ''}`;
          return tag.toUpperCase();
        });
        // Add metafield and tag corresponding to split test
        await fetch('/.netlify/functions/track-split-test-order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order: userCart.number,
            test_identifier: `Order Tags`,
            test_value: storedTags,
            test_tag: formattedTags,
          }),
        })
          .then((response) => response.json())
          .catch((error) => console.error('[AUG] Track Split Test Order Error', error));
      }

      stripe.redirectToCheckout({
        sessionId: cart.checkoutSessionId,
      });
    } catch (error) {
      if (!tCChecked) {
        setMessage(`Please agree to the T&C`);
      } else if (boxInCart && boxItemsQuantityInCart < 20) {
        setMessage(`Please add at least 20 total units in Build a Box `);
      } else if (stockError) {
        const message = `Sorry! Not all products are available in selected quantities. Please adjust your cart.`;
        setMessage(message);
      } else {
        setMessage(translate('Checkout Error'));
        console.log(error);
        console.log(error, { source: 'CheckoutButton' });
      }
    }

    setButtonIsLoading(false);
  };

  const promos = getAllFormatedPromotions(userCart);
  const promoCode = promos
    ? promos
        .filter((p) => p.code)
        .map((p) => p.code)
        .join(',')
    : [];

  return (
    <form onSubmit={handleSubmit}>
      {shareasaleTrack && (
        <img
          src={`https://www.shareasale.com/sale.cfm?tracking=${userCart.number}&amount=${userCart.displayTotal}&merchantID=111259&transtype=sale&couponcode=${promoCode}`}
          width="1"
          height="1"
          alt="shareasale tracking"
        />
      )}
      <Button
        tabIndex="0"
        type="submit"
        sx={{
          width: '100%',
          height: ['49px'],
          fontSize: '1.4rem !important',
          fontWeight: 700,
          transition: 'all 0.25s ease',
          backgroundColor: 'alizarinCrimson',
          borderRadius: '10px',
          color: 'canary',
          '&:hover': { boxShadow: '0.25rem 0.25rem 0rem 0px #69102c' },
          '&:focus': { outline: 'none' },
        }}
      >
        {buttonIsLoading && <Spinner data-testid="spinner" size="15" color="inherit" />}

        {!buttonIsLoading && (
          <>
            <Text sx={{ mr: '1rem' }}>{translate('cart.checkout')}</Text>
            <Text sx={{ mr: '1rem', fontWeight: 400 }}>|</Text>
            <Text>${estimatedTotal.toFixed(2)}</Text>
          </>
        )}
      </Button>
    </form>
  );
};

export default CheckoutButton;
