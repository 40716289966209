/** @jsx jsx */
import { useEffect, useRef, useContext } from 'react';
import { Flex, Text, jsx } from 'theme-ui';
import { gsap } from 'gsap';
import MessageContext from '~/contexts/MessageContext';

const NotificationSnackBar = ({ message }) => {
  const [, setMessage] = useContext(MessageContext);
  const snackbarRef = useRef();
  useEffect(() => {
    if (message) {
      gsap.to(snackbarRef.current, { bottom: 25, opacity: 1, duration: 0.5 });
      gsap.to(snackbarRef.current, {
        bottom: 0,
        opacity: 0,
        duration: 0.5,
        delay: 3,
      });
    }
    setTimeout(() => {
      setMessage('');
    }, [3500]);
  }, [message]);
  return (
    message && (
      <Flex
        ref={snackbarRef}
        tabIndex="-1"
        sx={{
          position: 'fixed',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 2147483651,
          bottom: 0,
          opacity: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRadius: '5px',
          px: '25px',
          py: '10px',
          backgroundColor: 'alizarinCrimson',
          color: 'canary',
          whiteSpace: 'pre-line',
        }}
      >
        <Text>{message}</Text>
      </Flex>
    )
  );
};

export default NotificationSnackBar;
