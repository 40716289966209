import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { trackCustomEvent } from '~/utils/analytics';

const BoxBuilderPopUpContext = React.createContext({
  itemCount: 0,
  setItemCount: () => {},
  content: {
    benefitsIcons: [],
    benefits: [],
    images: [],
    details: null,
    detailSections: [],
  },
  isBannerVisible: false,
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  isMinimized: false,
  minimizeWidget: () => {},
  didAddToCart: false,
  setDidAddToCart: () => {},
});

export const BoxBuilderPopUpProvider = ({ children }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const [didTrackItemSelection, setDidTrackItemSelection] = useState(false);
  const [didAddToCart, setDidAddToCart] = useState(false);

  const openModal = () => {
    setIsMinimized(false);
    setIsModalOpen(true);
    trackCustomEvent('Box Builder Modal Opened', { location: window.location.pathname });
  };

  const closeModal = () => {
    setIsMinimized(false);
    setIsModalOpen(false);
  };

  const data = useStaticQuery(graphql`
    query BoxBuilderContent {
      contentfulBoxBuilderWidget(slug: { eq: "box-builder-widget" }) {
        benefitsIcons {
          id
          svg {
            dataURI
          }
        }
        benefits
        images {
          id
          gatsbyImageData(aspectRatio: 1, placeholder: DOMINANT_COLOR)
          title
        }
        details {
          raw
        }
        detailSections {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
        defaultSubscriptionInterval {
          id
          name
          slug
          presentation
          length
          unit
        }
      }
    }
  `);

  const content = data.contentfulBoxBuilderWidget ?? {
    benefitsIcons: [],
    benefits: [],
    images: [],
    details: null,
    detailSections: [],
    defaultSubscriptionInterval: null,
  };

  useEffect(() => {
    if (itemCount === 0 || didTrackItemSelection) return;

    trackCustomEvent('Box Builder Item Added', { location: window.location.pathname });
    setDidTrackItemSelection(true);
  }, [itemCount, didTrackItemSelection]);

  useEffect(() => {
    if (!didAddToCart) return;

    trackCustomEvent('Box Builder Added to Cart', { location: window.location.pathname });
  }, [didAddToCart]);

  useEffect(() => {
    const avoiduserwayChanges =
      window.location.pathname.indexOf('/products') === 0 ||
      window.location.pathname.indexOf('/shop') === 0;
    const scrollBasedBannerPaths = ['/', '/products/box-builder', '/products/box-builder/'];

    if (scrollBasedBannerPaths.includes(window.location.pathname)) {
      // Update third-party widgets position based on scroll

      const handleScroll = () => {
        const attv = document.querySelector('#attentive_creative');
        const userway = document.querySelector('#userwayAccessibilityIcon');

        const upperStickyRef = document.getElementById('upperSticky');
        const lowerStickyRef = document.getElementById('lowerSticky');
        const topThreshold = upperStickyRef?.getBoundingClientRect().top;
        const bottomThreshold = lowerStickyRef?.getBoundingClientRect().top;

        const shouldBannerBeVisible =
          topThreshold < window.innerHeight * 0.7 && bottomThreshold > window.innerHeight * 0.7;

        const attvBottom = attv?.style.bottom;
        const userwayBottom = userway?.style.bottom;

        const dataAttvBottom = attv?.getAttribute('data-original-position');
        const dataUserwayBottom = userway?.getAttribute('data-original-position');

        if (attv && attvBottom && attvBottom !== '0px' && !dataAttvBottom)
          attv.setAttribute('data-original-position', attvBottom);
        if (userway && userwayBottom && userwayBottom !== '0px' && !dataUserwayBottom)
          userway.setAttribute('data-original-position', userwayBottom);

        if (attv && dataAttvBottom)
          attv.style.bottom = shouldBannerBeVisible ? '120px' : dataAttvBottom;
        if (userway && dataUserwayBottom)
          userway.style.bottom = shouldBannerBeVisible ? '122px' : dataUserwayBottom;

        setIsBannerVisible(shouldBannerBeVisible);
      };

      document.addEventListener('scroll', handleScroll);

      return () => document.removeEventListener('scroll', handleScroll);
    }

    // Update third-party widgets position assuming we'll always show either banner or indicator
    const timeout = setTimeout(() => {
      const attv = document.querySelector('#attentive_creative');
      const userway = document.querySelector('#userwayAccessibilityIcon');

      if (attv) attv.style.bottom = avoiduserwayChanges ? '10px' : '120px';
      if (userway) userway.style.bottom = avoiduserwayChanges ? '10px' : '122px';
      setIsBannerVisible(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  // Select the default interval from Contentful if the there is no stored interval or the stored interval === null ~~~ (Select....)
  const initialinterval =
    typeof window !== 'undefined'
      ? localStorage.getItem('intervalRef') && localStorage.getItem('intervalRef') !== 'null'
        ? JSON.parse(localStorage.getItem('intervalRef'))
        : content.defaultSubscriptionInterval
      : null;

  const buttonRef = useRef();
  const [dynamicButton, setDynamicButton] = useState(false);
  const [totalsArr, setTotalsArr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [selectedInterval, setSelectedInterval] = useState(initialinterval);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [isReplacingBox, setIsReplacingBox] = useState(false);

  return (
    <BoxBuilderPopUpContext.Provider
      value={{
        itemCount,
        content,
        isBannerVisible,
        isModalOpen,
        isMinimized,
        openModal,
        closeModal,
        setItemCount,
        minimizeWidget: () => {
          setIsMinimized(true);
          trackCustomEvent('Box Builder Minimized', { location: window.location.pathname });
        },
        didAddToCart,
        setDidAddToCart,
        dynamicButton,
        setDynamicButton,
        buttonRef,
        totalsArr,
        setTotalsArr,
        selectedInterval,
        setSelectedInterval,
        buttonIsLoading,
        setButtonIsLoading,
        isReplacingBox,
        setIsReplacingBox,
      }}
    >
      {children}
    </BoxBuilderPopUpContext.Provider>
  );
};

export default BoxBuilderPopUpContext;

export const useBoxBuilder = () => useContext(BoxBuilderPopUpContext);
