import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import ProductAddToBagButton from '../Product/AddToBagButton';
import { DeliverySelection } from './DeliverySelection';
import { SubscriptionInfo } from './SubscriptionInfo';
import WidgetBoxItemsContainer from './WidgetBoxItemsContainer';
import ProgressBar from '../Cart/ProgressBar';
import { customBoxSkus } from '~/utils/GetSkus';

export const WidgetAddToBag = ({
  showBABWidget = false,
  disabledError,
  selectedInterval,
  isSubscription,
  prodVariantsNoBox,
  prodVariants,
  subscription,
  prodBox,
  increment,
  maxQuantity,
  activeTotalQuantity,
  product,
  totalsArr,
  cartTotalArr,
  intervals,
  onIntervalChange,
  setTotalsArr,
  isItWidget,
  showDeliveryError,
  handleShowDeliveryError,
  setSelectedTab,
  promoIsLive,
  totalPrice,
  cartInterval,
}) => {
  const discount = 0.1;

  const formatPrice = (number) => Number(Math.round(number * 100) / 100).toFixed(2);

  const discountPrice = totalPrice - totalPrice * discount;

  const buildABoxCollection = product.collection.find((c) => c.slug === 'custom');
  const items = buildABoxCollection?.products;

  const widgetBoxTotal = totalPrice < 5 ? 0 : totalPrice; // dont show price below $5 as it means no items have been selected

  const promoMessage = null;
  return (
    <Flex
      sx={{
        position: ['absolute', 'absolute', 'unset'],
        bottom: ['-0.2rem', null, null],
        height: ['10.75rem', '10.75rem', 'fit-content'],
        minHeight: ['10.75rem', '10.75rem', '45%'],
        maxHeight: ['10.75rem', '10.75rem', '100%'],
        alignItems: 'center',
        width: ['100%', '100%', '40%'],
        minWidth: ['100%', '100%', '38rem'],
        maxWidth: ['100%', '100%', '43rem'],
        p: ['1.25rem', '1.25rem', '3rem min(4rem,3.5%)'],
        backgroundColor: 'canary',
        transition: showBABWidget ? 'all 0.6s ease-in-out' : 'all 1s ease-in-out',
        zIndex: 50,
        justifyContent: 'space-between',
        borderWidth: ['0.2rem 0 0 0', '0.2rem 0 0 0', '0.2rem'],
        borderRadius: [0, 0, '1rem'],
        borderColor: 'alizarinCrimson',
        borderStyle: 'solid',
        flexDirection: ['row', 'row', 'column'],
        overflow: 'scroll',
        '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
        scrollbarWidth: 'none' /* Firefox */,
        '&::-webkit-scrollbar': {
          display: 'none' /* Safari and Chrome */,
        },
        flexWrap: ['wrap', 'wrap', 'nowrap'],
      }}
    >
      <AdditionalInfo
        widgetBoxTotal={widgetBoxTotal}
        forwardSX={{ display: ['flex', 'flex', 'none'] }}
        promoMessage={promoMessage}
      />
      <Box sx={{ display: ['none', 'none', 'block'], width: '100%' }}>
        <DeliverySelection
          intervals={intervals}
          onIntervalChange={onIntervalChange}
          selectedInterval={selectedInterval}
          showDeliveryError={showDeliveryError}
          promoIsLive={promoIsLive}
          totalPrice={totalPrice}
        />
      </Box>

      {/* warning/instruction message  */}
      {disabledError ? (
        <Text
          sx={{
            width: ['45%', '45%', '100%'],
            fontSize: ['1.2rem', '1.2rem', '1.6rem'],
            fontStyle: 'italic',
            textAlign: ['left', 'left', 'center'],
            borderTop: [0, 0, '0.2rem solid #CF2029'],
            borderBottom: [0, 0, '0.2rem solid #CF2029'],
            py: [0, 0, '3rem'],
            mb: [0, 0, '3rem'],
          }}
        >
          {disabledError}
        </Text>
      ) : (
        // Line item and total
        <Flex
          sx={{
            width: ['45%', '45%', '100%'],
            fontWeight: 700,
            flexDirection: 'column',
            borderTop: [0, 0, '0.2rem solid #CF2029'],
            borderBottom: [0, 0, '0.2rem solid #CF2029'],
            py: [0, 0, '2rem'],
            mb: [0, 0, '2rem'],
          }}
        >
          <WidgetBoxItemsContainer
            items={items}
            mainBoxItem={prodBox}
            isSubscription={isSubscription}
            totalsArr={totalsArr}
            setTotalsArr={setTotalsArr}
          />
          <Flex
            sx={{
              width: '100%',
              mb: '0.5rem',
              borderTop: [0, 0, '0.1rem solid #CF2029'],
              justifyContent: 'space-between',
              pt: [0, 0, '2rem'],
            }}
          >
            <Text
              sx={{
                fontWeight: 700,
                fontSize: '1.6rem',
                display: ['none', 'none', 'block'],
              }}
            >
              Total
            </Text>
            <Flex>
              <Text
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  pr: isSubscription ? ['0.5rem', '0.7rem', '0.7rem'] : 0,
                  textDecoration: isSubscription ? 'underline' : 'none',
                  textUnderlineOffset: '-0.6rem',
                  textDecorationSkipInk: 'none',
                  textDecorationThickness: '0.1rem',
                  color: 'alizarinCrimson',
                }}
              >
                ${formatPrice(totalPrice)}
              </Text>
              {isSubscription ? (
                <Text
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'discountGreen',
                  }}
                >
                  ${formatPrice(discountPrice)}
                </Text>
              ) : null}
            </Flex>
          </Flex>
          <Flex sx={{ display: ['flex', 'flex', 'none'] }}>
            <Text sx={{ fontWeight: 400, fontSize: '1.2rem', fontStyle: 'italic' }}>
              {activeTotalQuantity} Items. {selectedInterval?.name?.split('(')[0]}
            </Text>
          </Flex>
        </Flex>
      )}

      {/* Add to bag Button */}
      <AdditionalInfo
        widgetBoxTotal={widgetBoxTotal}
        forwardSX={{ display: ['none', 'none', 'flex'] }}
        promoMessage={promoMessage}
      />

      <Box sx={{ width: ['50%', '50%', '100%'] }}>
        <ProductAddToBagButton
          monthly={selectedInterval && selectedInterval.name !== 'Buy Once (Regular Price)'}
          prodVariantsNoBox={prodVariantsNoBox}
          interval={
            selectedInterval && selectedInterval.name !== 'Buy Once (Regular Price)'
              ? selectedInterval
              : undefined
          }
          length={selectedInterval?.length}
          subscription={
            selectedInterval && selectedInterval.name !== 'Buy Once (Regular Price)'
              ? subscription
              : null
          }
          prodVariants={prodVariants}
          prodBox={prodBox}
          increment={increment}
          maxQuantity={maxQuantity}
          activeTotalQuantity={activeTotalQuantity}
          product={product}
          totalsArr={totalsArr}
          cartTotalArr={cartTotalArr}
          totalPrice={totalPrice}
          disabledError={disabledError}
          isItWidget={isItWidget}
          handleShowDeliveryError={handleShowDeliveryError}
          setSelectedTab={setSelectedTab}
          cartInterval={cartInterval}
          selectedInterval={selectedInterval}
        />
      </Box>
      <Box sx={{ display: ['none', 'none', 'block'] }}>
        <SubscriptionInfo />
      </Box>
    </Flex>
  );
};

const AdditionalInfo = ({ widgetBoxTotal, forwardSX, promoMessage }) => {
  const { cart } = useCart();
  const nonBoxCartTotal = cart?.lineItems
    ?.filter(
      (item) => !customBoxSkus.includes(item.variant.sku) && item.variant.sku !== 'box-builder'
    )
    .reduce((a, c) => {
      return Number(c.total) + a;
    }, 0);
  return (
    <Box sx={{ width: '100%', ...forwardSX }}>
      {promoMessage ? (
        <Flex
          sx={{
            alignItems: 'center',
            height: '2.5rem',
            justifyContent: 'center',
            width: '100%',
            border: '1px solid #CF2029',
            backgroundColor: '#CF2029',
            borderRadius: '2.5rem',
            mb: '0.75rem',
          }}
        >
          <Text
            sx={{
              fontWeight: 700,
              fontSize: ['1.2rem', '1.2rem', '1.2rem'],
              textAlign: 'center',
              color: '#fff',
              minWidth: '100%',
            }}
          >
            {promoMessage}
          </Text>
        </Flex>
      ) : (
        <ProgressBar total={widgetBoxTotal + nonBoxCartTotal} />
      )}
    </Box>
  );
};
