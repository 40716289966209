import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useCatalogQuery } from '@chordcommerce/gatsby-theme-autonomy/src/hooks/graphql/queries/use-catalog';

const useProductExtraDetails = ({ slug, sku }) => {
  const { catalog: { collections = [] } = { collections: [] } } = useCatalogQuery();

  const products = collections.reduce((acc, collection) => [...acc, ...collection.products], []);

  const [details, setDetails] = useState(null);

  useEffect(() => {
    const product = _.find(products, (p) => p.slug === slug);
    // TODO: Figure out OMS issues

    if (!product) return;

    const isKit = sku.includes('|');

    let deets = null;

    if (isKit) deets = product;
    else if (product.variants) {
      deets = _.find(product.variants, (v) => v.sku === sku);
    }

    if (deets) setDetails(deets);
  }, [products, sku, slug]);

  return { details };
};

export default useProductExtraDetails;
