/** @jsx jsx */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex, jsx, Text } from 'theme-ui';
import WidgetBoxSubLineItem from './WidgetBoxSubLineItem';

const WidgetBoxLineItem = ({
  slug,
  name,
  localSubItems,
  isSubscription,
  details,
  totalsArr,
  setTotalsArr,
}) => {
  const url = `/products/${slug}/`;
  const [image, setImage] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (details) {
      const { mainImage } = details;
      setImage(mainImage);
    }
  }, [details]);

  return localSubItems.length ? (
    <Flex
      sx={{
        flexDirection: 'row',
        fontWeight: 700,
        mb: '0.5rem',
        flexWrap: 'wrap',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 1,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            color: 'alizarinCrimson',
            flexGrow: 1,
          }}
        >
          <Flex
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Text
              sx={{
                fontSize: '1.4rem',
                textTransform: 'uppercase',
                mb: '1rem',
                color: 'alizarinCrimson',
              }}
            >
              {name}
            </Text>
          </Flex>
          <Text sx={{ fontSize: ['1rem', '1.2rem'], color: 'alizarinCrimson', fontWeight: 400 }}>
            {isSubscription
              ? `Every ${isSubscription.length} ${
                  isSubscription.length > 1 ? 'months' : 'month'
                } subscription${' '}`
              : 'One-time purchase'}
          </Text>
        </Flex>
        {image && (
          <Box
            sx={{
              minWidth: '9.4rem',
              maxWidth: '9.4rem',
              position: 'relative',
            }}
          >
            <Link to={url}>
              <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
            </Link>
          </Box>
        )}
      </Flex>
      <Flex
        sx={{
          flexGrow: 1,
          width: '70%',
          display: 'block',
          maxHeight: '50%',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            flexGrow: 1,
            mt: '-2.5rem',
          }}
        >
          {_.map(
            localSubItems,
            (li, idx) =>
              !!totalsArr[idx] && (
                <WidgetBoxSubLineItem
                  key={li.id}
                  id={idx}
                  name={li && li.name}
                  quantity={totalsArr[idx]}
                  isUpdating={isUpdating}
                  setIsUpdating={setIsUpdating}
                  idx={idx}
                  setTotalsArr={setTotalsArr}
                />
              )
          )}
        </Flex>
      </Flex>
    </Flex>
  ) : null;
};

export default WidgetBoxLineItem;
