/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { useCart, useTranslate, utils } from '@chordcommerce/gatsby-theme-autonomy';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useContext, useState } from 'react';
import { Box, Button, Flex, Heading, Select, Spinner, jsx } from 'theme-ui';
import MessageContext from '~/contexts/MessageContext';
import useSelectedOrFirstAvailableVariant from '~/hooks/components/use-selected-or-first-available-variant';
import { updateStoredTags } from '~/utils/misc/storeTags';
import updateCreatorIQMetric from '~/utils/misc/updateCreatorIQMetric';

const AddonCard = ({ product }) => {
  const translate = useTranslate();
  const { cart, addToCart } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useContext(MessageContext);

  const {
    selectedVariant,
    option1Values,
    selectedOption1,
    selectedOption2,
    selectOption1,
    selectOption2,
  } = useSelectedOrFirstAvailableVariant(product);

  const option2Values = selectedOption1?.combinations ?? [];
  const name = product.name.includes('(Add-On)') ? product.name.slice(0, -9) : product.name;

  const { toUsdCurrency } = utils;

  const addVariantToCart = async () => {
    setIsLoading(true);
    const tagName = name
      .replace('-pack', '')
      .replace(/[^A-Za-z ]+/g, '')
      .trim()
      .replaceAll(' ', '_')
      .replaceAll('__', '_');

    updateStoredTags({
      tagKey: tagName,
      tagValue: { tag: `ADD_ON_${tagName}`, edited: false },
    });
    try {
      await addToCart({ sku: selectedVariant?.sku, quantity: 1 });

      if (typeof window !== 'undefined') {
        window.ttq.track('AddToCart', { value: cart.total, currency: 'USD' });
      }

      await updateCreatorIQMetric();
    } catch (error) {
      console.error(error);
      setMessage(translate('error.api.default'));
    }

    setIsLoading(false);

    setTimeout(() => {
      setMessage(null);
    }, 4000);
  };

  return (
    <Box
      as="article"
      sx={{
        minWidth: ['13rem !important'],
        height: '100%',
        textAlign: 'center',
        border: '1px solid transparent',
        borderColor: 'alizarinCrimson',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
      key={product.slug}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          p: '1rem',
          minHeight: '13rem',
        }}
      >
        {selectedVariant?.mainImage ? (
          <GatsbyImage
            image={selectedVariant.mainImage.gatsbyImageData}
            alt=""
            sx={{ width: '5.5rem', height: '5.5rem' }}
          />
        ) : null}

        <Heading as="h4" sx={{ fontSize: [1], textTransform: 'uppercase', my: '1rem' }}>
          {name}
        </Heading>

        <Flex sx={{ height: '1.5rem', alignItems: 'center', justifyContent: 'center' }}>
          {option1Values
            ?.filter((option) => option.available)
            .map((option) => (
              <Box sx={{ mr: '0.6rem', ':last-of-type': { mr: 0 } }} key={option.slug}>
                <button
                  aria-label={option.presentation}
                  type="button"
                  onClick={() => selectOption1(option)}
                  sx={{
                    display: 'flex',
                    width: '1.5rem',
                    height: '1.5rem',
                    padding: '0 !important',
                    backgroundColor: option.optionalRendering,
                    border: '1px solid transparent',
                    borderColor:
                      option.slug === selectedOption1?.slug ? 'alizarinCrimson' : 'transparent',
                    borderRadius: '100%',
                  }}
                />
              </Box>
            ))}
        </Flex>
      </Flex>
      <Box
        sx={{
          p: '1rem',
          fontSize: [3],
          textTransform: 'uppercase',
          color: 'white',
          backgroundColor: 'alizarinCrimson',
        }}
      >
        <Box
          sx={{
            fontSize: [3],
            textAlign: 'center',
          }}
        >
          {option2Values.length ? (
            <Select
              value={selectedOption2.slug}
              onChange={({ target: { value } }) => {
                selectOption2(option2Values.find((v) => v.slug === value));
              }}
              sx={{
                height: 'max-content',
                textAlign: 'center',
                fontWeight: 'bold',
                py: 0,
                textTransform: 'uppercase',
                backgroundColor: 'transparent',
                border: '0',
                ':focus': {
                  fontSize: [3],
                },
                '& option': {
                  color: 'alizarinCrimson',
                },
                'option:checked': {
                  color: '#ffffff',
                },
                '+ svg': {
                  transform: 'translateY(-5px)',
                },
              }}
            >
              {option2Values.map((option) => (
                <option value={option.slug} key={option.slug}>
                  {option.name}
                </option>
              ))}
            </Select>
          ) : (
            <Heading as="h4" sx={{ fontWeight: 'bold' }}>
              Add to cart
            </Heading>
          )}
        </Box>

        <Button
          onClick={addVariantToCart}
          disabled={!selectedVariant?.available}
          sx={{
            minWidth: '5.6rem',
            width: 'max-content',
            color: 'canary',
            border: '1px solid transparent',
            borderColor: 'canary',
            borderRadius: '20rem',
            mt: option2Values.length ? '-1px' : '1rem',
          }}
        >
          {isLoading && <Spinner data-testid="spinner" size="12" color="inherit" />}
          {isLoading
            ? null
            : selectedVariant?.available
            ? toUsdCurrency(selectedVariant?.price)
            : 'Out of Stock'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddonCard;
