/** @jsx jsx */
import PropTypes from 'prop-types';
import { Box, jsx } from 'theme-ui';
import NavMenu from '~/components/Nav/Menu';

export const NavBar = ({ itemCount, data }) => (
  <Box
    as="nav"
    role="navigation"
    sx={{
      backgroundColor: 'inherit',
      width: '100%',
    }}
  >
    <NavMenu itemCount={itemCount} data={data} />
  </Box>
);

NavBar.propTypes = {
  itemCount: PropTypes.number,
};

export default NavBar;
