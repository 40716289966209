import { graphql, useStaticQuery } from 'gatsby';

const useBurgerMenu = () => {
  const data = useStaticQuery(
    graphql`
      query BurgerMenuQuery($locale: String) {
        menu: contentfulNavigationBar(slug: { eq: "burger-menu" }, node_locale: { eq: $locale }) {
          links {
            type
            text
            url
            page {
              name
              slug
            }
            scrollDivId
          }
        }
      }
    `
  );

  const links = data.menu.links.map((link) => {
    if (link.scrollDivId)
      return {
        ...link,
        page: { ...link.page, name: link.scrollDivId, slug: link.scrollDivId },
      };

    if (link.type !== 'Page') return link;

    if (link?.page?.slug !== 'box-builder') return link;

    return { ...link, page: { ...link.page, slug: 'products/box-builder' } };
  });

  return { menu: { links } };
};

export default useBurgerMenu;
