/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Container, Flex, Grid, jsx, Text } from 'theme-ui';
import FooterLegal from '~/components/Footer/Legal';
import useFooterQuery from '~/hooks/graphql/queries/use-footer';
import AugustA from '~/assets/images/August-A.svg';

import EmailCapture from './EmailCapture';
import ContentLink from '../Generic/ContentLink';

export const Footer = () => {
  const { august, help, socials, legal } = useFooterQuery();

  return (
    <Container
      as="footer"
      variant="fullWidth"
      sx={{
        position: 'relative',
        padding: ['4.5rem 2.4rem 5rem 2.4rem', '6rem 4rem'],
        zIndex: 1,
        backgroundColor: 'alizarinCrimson',
        borderTop: '5px solid transparent',
        borderColor: 'canary',
      }}
    >
      <Box
        sx={{
          mx: 'auto',
          display: ['block', null, 'none'],
          mb: '4.5rem',
        }}
      >
        <EmailCapture />
      </Box>

      <Grid
        columns={[1, 3, 'repeat(3, 1fr) 2fr']}
        sx={{
          margin: '0 auto',
          columnGap: '4rem',
          rowGap: ['4rem', null, '0rem'],
          minHeight: ['auto', '33rem', '33rem'],
          mb: '10rem',
        }}
      >
        <Flex
          sx={{
            gridColumnStart: [1, null, 'auto'],
            flexDirection: 'column',
            a: {
              width: 'max-content',
            },
            '& > a:not(:first-of-type)': {
              variant: 'text.footerLink',
            },
          }}
        >
          <Text
            as={Link}
            sx={{ variant: 'text.footerHeading' }}
            aria-label="Link to august homepage"
            to="/"
          >
            AUGUST
          </Text>

          {august.links.map((link, index) => (
            <ContentLink link={link} key={index} />
          ))}
        </Flex>

        <Flex
          sx={{
            gridColumnStart: [1, null, 'auto'],
            flexDirection: 'column',
            marginBottom: '2.5rem',
            '& > a': {
              variant: 'text.footerLink',
              width: 'max-content',
              mb: '1rem',
            },
          }}
        >
          <Text sx={{ variant: 'text.footerHeading' }}>HELP</Text>

          {help.links.map((link, index) => (
            <ContentLink link={link} key={index} />
          ))}
        </Flex>

        <Flex sx={{ gridColumnStart: [1, null, 'auto'], flexDirection: 'column' }}>
          <Text sx={{ variant: 'text.footerHeading' }}>SOCIAL</Text>

          <Flex
            sx={{
              '& > a': {
                mr: '2rem',
              },
              '& > a > svg': {
                height: '2rem',
                width: '2rem',
                mr: 0,
                '& > path': { fill: (t) => t.colors.canary },
              },
            }}
          >
            {socials.links.map((link, index) => (
              <ContentLink link={link} key={index} />
            ))}
          </Flex>
        </Flex>

        <Flex
          sx={{
            display: ['none', null, 'flex'],
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            '& > a': { variant: 'text.footerLink', mb: '.5rem' },
          }}
        >
          <EmailCapture />
        </Flex>
      </Grid>

      <Flex
        sx={{
          justifyContent: 'space-between',
          margin: 0,
          alignItems: 'flex-start',
          mt: 0,
        }}
      >
        <Box>
          <AugustA />
        </Box>

        <Box sx={{ flex: '1 1 0' }}>
          <FooterLegal links={legal.links} />
        </Box>
      </Flex>
    </Container>
  );
};

export default Footer;
