import React from 'react';
import { Flex, Text } from 'theme-ui';

export const SubscriptionInfo = () => {
  return (
    <Flex
      sx={{
        height: '15rem',
        flexDirection: 'column',
        color: 'darkTan',
        px: ['1.5rem', '1.5rem', 0],
      }}
    >
      <Text
        sx={{
          textTransform: 'uppercase',
          mt: '3.1rem',
          pb: '1.5rem',
          mb: '1.5rem',
          borderBottom: '2px solid #69102c',
          fontWeight: 700,
          fontSize: '1.4rem',
        }}
      >
        about auto-delivery
      </Text>
      <Text sx={{ fontSize: '1.3rem', fontWeight: 400, lineHeight: '150%' }}>
        Edit, skip, or cancel your subscription at anytime. Plus 10% off every subscription order.
        Free shipping on all orders over $50.
      </Text>
    </Flex>
  );
};
