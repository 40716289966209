/** @jsx jsx */
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Flex, jsx, Select, Spinner } from 'theme-ui';
import ArrowDown from '~/assets/images/icons/arrow_down_alizarinCrimson.svg';

const QuantitySelector = ({ handleChange, options = [], isUpdating, ...props }) => {
  const { register, getValues } = useForm();
  const { isLoading, defaultValue } = props;

  const onChange = () => {
    const quantity = getValues('quantity');
    if (handleChange) handleChange(quantity);
  };

  return (
    <Flex
      {...props}
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '56px',
        width: '56px',
        height: '34px',
        position: 'relative',
        svg: {
          display: 'none',
        },
        '.spinner': {
          display: 'block',
          height: '100%',
          width: '100%',
        },
        '.arrow-down': {
          display: 'block',
          position: 'absolute',
          right: '8px',
          pointerEvents: 'none',
        },
      }}
    >
      {isLoading && <Spinner className="spinner" color="inherit" />}
      {!isLoading && (
        <Fragment>
          <Select
            sx={{
              fontFamily: 'halcom',
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '13px',
              fontWeight: 700,
              borderRadius: '5px',
              color: 'darkTan',
              borderWidth: '1px',
              outline: 'none',
            }}
            value={defaultValue}
            name="quantity"
            onChange={onChange}
            ref={register}
            disabled={isUpdating || props.outOfStock}
          >
            {options &&
              options.map(({ name, value }) => (
                <option sx={{ fontFamily: 'Arial' }} key={value} value={value}>
                  {name || value}
                </option>
              ))}
          </Select>
          <ArrowDown
            className="arrow-down"
            sx={{
              path: {
                stroke: 'darkTan',
              },
            }}
          />
        </Fragment>
      )}
    </Flex>
  );
};

QuantitySelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })
  ),
};

export default QuantitySelector;
