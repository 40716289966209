/* eslint-disable no-nested-ternary */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Box, Button, Grid, Text } from 'theme-ui';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';

export const BoxBuilderBanner = () => {
  const { isBannerVisible, isModalOpen, itemCount, openModal } = useBoxBuilder();

  return (
    <Grid
      sx={{
        position: 'fixed',
        bottom: isModalOpen || itemCount > 0 ? '-100%' : isBannerVisible ? 0 : '-100%',
        left: 0,
        gridTemplateColumns: ['11rem 1fr', '12rem 1fr auto'],
        alignItems: 'center',
        width: '100%',
        py: ['1.6rem'],
        px: ['2rem', null, null, 'max(2rem, calc((100vw - 1280px) / 2))'],
        backgroundColor: 'alizarinCrimson',
        borderRadius: '20px 20px 0 0',
        transition: isBannerVisible ? 'all 1s ease-in' : 'all 1s ease-in',
        zIndex: 50,
      }}
    >
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Box sx={{ position: 'absolute', bottom: '-1.6rem', left: 0 }}>
          <StaticImage src="../../assets/images/AugustBoxOnCloud.png" quality={100} />
        </Box>
      </Box>

      <Text as="p" sx={{ display: ['none', 'block'], color: 'canary', textAlign: 'center' }}>
        Customize your box to get exactly what you need and we’ll take care of the rest!
      </Text>

      <Button
        onClick={openModal}
        sx={{ variant: 'buttons.ctaHighlight', fontSize: ['14px'], px: ['1.6rem !important'] }}
      >
        Build your box
        <ArrowRight />
      </Button>
    </Grid>
  );
};
