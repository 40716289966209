/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAnalytics, useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Box, Button, Flex, jsx, Spinner, Text, useThemeUI } from 'theme-ui';
import Close from '~/assets/images/icons/close-alizarinCrimson.svg';
import useProductExtraDetails from '~/hooks/components/use-product-extra-details';
import CartQuantitySelector from '../QuantitySelector';
import useProduct from '~/hooks/components/use-product';
import useVariantAvailability from '~/hooks/components/useVariantAvailability';
import { updateStoredTags } from '~/utils/misc/storeTags';
import { DeliverySelectionDropdown } from '~/components/Generic/DeliverySelectionDropdown';
import { CartSpinner } from '../CartSpinner/CartSpinner';

const LineItem = ({
  id,
  lineItem,
  sku,
  slug,
  name,
  itemPromos,
  options,
  quantity,
  singleDisplayAmount,
  orderConfirmed = false,
  variant,
  setOutOfStock,
  NonSubscriptionItems,
  intervalOptions,
  setDonationBoxLoading = () => {},
  giftCards = [],
}) => {
  const { theme } = useThemeUI();
  const { addToCart, addSubscription, removeFromCart } = useCart();
  const product = useProduct({ slug });
  const { trackProductClicked } = useAnalytics();
  const [isLoading, setIsLoading] = useState(true);
  const path = sku.includes('|') ? 'bundles' : 'products';
  const url = `/${path}/${slug}/`;
  const renderedOptions = options
    ? _.map(options.split(', '), (option) => option.split(': ')[1])
    : null;

  const [image, setImage] = useState(null);
  const { details } = useProductExtraDetails({ sku, slug });

  const { totalOnHand } = useVariantAvailability({
    sku,
  });

  const isSubscription = !!lineItem?.subscriptionLineItems.length;
  const isDonationBox = sku === 'donation-box-5';
  const subscriptionPrice = `$${(lineItem.price - lineItem.price * 0.1).toFixed(2)}`;

  const updateSubscriptionOptions = async (e) => {
    setIsLoading(true);
    isDonationBox && setDonationBoxLoading(true);
    const newInterval = intervalOptions.find((s) => s.name === e.target.value) ?? null;
    try {
      await removeFromCart({ lineItemId: id });
      if (newInterval) {
        await addSubscription({
          sku,
          quantity,
          endDate: null,
          interval: {
            length: newInterval.length,
            unit: 'month',
          },
        });
      } else {
        await addToCart({ sku, quantity });
      }
      setIsLoading(false);
      isDonationBox && setDonationBoxLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      isDonationBox && setDonationBoxLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (setOutOfStock) {
      setOutOfStock((prev) => ({ ...prev, [`${id}`]: totalOnHand < quantity }));
    }
  }, [totalOnHand, quantity]);

  useEffect(() => {
    if (details) {
      const { mainImage } = details;
      setImage(mainImage);
    } else {
      setImage(variant.images[0]);
    }
  }, [details]);

  const onlyOneVariant =
    NonSubscriptionItems.filter((item) => item.variant.name === name).length === 1;

  const subscriptionSKUs = [
    'travel-20ct',
    'august-sample-longnight',
    'august-sample',
    'donation-box-5',
  ];

  const hasSubscriptionOptions =
    subscriptionSKUs.includes(sku) &&
    (lineItem?.subscriptionLineItems[0]?.interval || !orderConfirmed);

  if (isLoading) return <CartSpinner />;

  const giftCardOption = variant.optionValues?.find((ov) =>
    ['value', 'gift-card'].includes(ov.optionTypeName)
  );

  const displayName = name.includes('(Add-On)')
    ? name.slice(0, -9)
    : giftCards.length
    ? `${giftCardOption?.presentation} ${name}`
    : name;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        fontWeight: 700,
        borderTop: !orderConfirmed ? '1px solid canary' : null,
        pt: !orderConfirmed ? '1.5rem' : null,
        px: orderConfirmed ? 0 : '2rem',
        mb: '1.5rem',
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row'],
          alignItems: giftCards.length ? 'flex-start' : 'center',
          justifyContent: 'flex-start',
        }}
      >
        {image && (
          <Box
            sx={{
              width: '80px',
              maxWidth: '80px',
              position: 'relative',
              mr: orderConfirmed ? '30px' : 0,
            }}
          >
            <Link to={url} onClick={() => trackProductClicked({ product })}>
              {image.gatsbyImageData ? (
                <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
              ) : (
                <img
                  sx={{ width: '100%', objectFit: 'contain' }}
                  src={image.smallUrl}
                  alt={image.title}
                />
              )}
            </Link>
          </Box>
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            ml: orderConfirmed ? 0 : '20px',
            flexGrow: 1,
            color: 'alizarinCrimson',
          }}
        >
          <Text
            sx={{
              fontSize: '1.4rem',
              mb: '11px',
              position: 'relative',
              textTransform: 'uppercase',
              color: 'alizarinCrimson',
            }}
          >
            {displayName}{' '}
            {!orderConfirmed && !itemPromos && (
              <Button
                sx={{
                  variant: 'buttons.removeBox',
                  width: '1.5rem',
                  height: '1.5rem',
                  padding: '0 !important',
                  position: 'absolute',
                  right: '0',
                  top: 0,
                  svg: { width: '100%', height: '100%' },
                }}
                onClick={() => {
                  if (onlyOneVariant || subscriptionSKUs.includes(sku))
                    updateStoredTags(null, {
                      tagKey: displayName
                        .replace('-pack', '')
                        .replace(/[^A-Za-z ]+/g, '')
                        .trim()
                        .replaceAll(' ', '_')
                        .replaceAll('__', '_'),
                    });
                  removeFromCart({ lineItemId: id });
                }}
              >
                <Close />
              </Button>
            )}
          </Text>
          {hasSubscriptionOptions && !orderConfirmed && (
            <Box
              sx={{
                svg: {
                  color: 'darkTan',
                  marginTop: '-1rem',
                },
              }}
            >
              <DeliverySelectionDropdown
                onChange={updateSubscriptionOptions}
                selectedIntervalName={`${
                  isSubscription
                    ? `Every ${lineItem.subscriptionLineItems[0].interval} (Save 10%)`
                    : 'Buy Once (Regular Price)'
                }`}
                options={intervalOptions}
                showPlaceholder={false}
                hideSubDiscount={isDonationBox}
                forwardSX={{
                  mb: '1rem',
                }}
              />
            </Box>
          )}
          <Text sx={{ fontSize: ['1rem', '1.2rem'], color: 'alizarinCrimson', mb: '1rem' }}>
            {!hasSubscriptionOptions
              ? 'One-time purchase'
              : orderConfirmed
              ? `Every ${lineItem.subscriptionLineItems[0].interval} (Save 10%)`
              : ''}
          </Text>
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Flex sx={{ flexDirection: 'column' }}>
              <Text
                sx={{
                  textAlign: 'left',
                  fontSize: '1.2rem',
                  textTransform: 'uppercase',
                  color: 'alizarinCrimson',
                  fontWeight: 400,
                }}
              >
                {!giftCards.length && (
                  <Text
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 700,
                      marginRight: '0.5rem',
                      color: 'alizarinCrimson',
                      textDecoration: isSubscription && !isDonationBox ? 'line-through' : 'none',
                    }}
                  >
                    {singleDisplayAmount}
                  </Text>
                )}
                {isSubscription && !isDonationBox ? (
                  <Text
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 700,
                      marginRight: '0.5rem',
                      color: 'discountGreen',
                    }}
                  >
                    {subscriptionPrice}
                  </Text>
                ) : null}
              </Text>
              {renderedOptions && (
                <Text sx={{ fontSize: '1.4rem', mt: '0.5rem' }}>
                  {!giftCards.length && `${renderedOptions.reverse().join(', ')}`}
                </Text>
              )}
            </Flex>
            {!orderConfirmed && !giftCards.length && (
              <CartQuantitySelector
                quantity={quantity}
                lineItemId={id}
                totalOnHand={totalOnHand}
                sku={sku}
                hideRemove={itemPromos}
              />
            )}
          </Flex>
          {quantity > totalOnHand ? (
            <Text
              sx={{
                textAlign: 'left',
                fontSize: ['1rem', '1.2rem'],
                textTransform: 'uppercase',
                color: 'alizarinCrimson',
                fontWeight: 900,
                whiteSpace: ['pre-line', 'unset', 'unset'],
                mb: '1.5rem',
                mt: '0.25rem',
              }}
            >
              {`Only ${totalOnHand} in stock.
              Please reduce the quantity.`}
            </Text>
          ) : null}
          {itemPromos ? (
            <Text
              sx={{
                textAlign: 'left',
                fontSize: ['1rem', '1.2rem'],
                textTransform: 'uppercase',
                color: 'discountGreen',
                fontWeight: 900,
                whiteSpace: ['pre-line', 'unset', 'unset'],
                mb: '1.5rem',
                mt: '0.25rem',
              }}
            >
              {itemPromos?.label?.split('-')[0]}
            </Text>
          ) : null}

          {!!giftCards.length && (
            <>
              {giftCards.map((card, index) => (
                <Box sx={{ mt: '1rem', mb: '0' }}>
                  {giftCards.length > 1 && (
                    <Text as="p" sx={{ fontSize: '10px', width: '155px', color: 'oldBrick' }}>
                      Gift Card #{index + 1}
                    </Text>
                  )}

                  <Text as="p" sx={{ fontSize: '10px', width: '155px', color: 'oldBrick' }}>
                    Recipient: {card.recipientEmail}
                  </Text>
                </Box>
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

LineItem.propTypes = {
  id: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  quantity: PropTypes.number,
  orderConfirmed: PropTypes.bool,
};

export default LineItem;
