export const getIncrement = (index, pvnb) =>
  // pvnb = prodVariantsNoBox
  pvnb[index].increment;

// This object indicates how many items to remove from the totals arr so that we don't map over the efficient but not listed quantities in our initial calculation
const sliceLengthObj = {
  'pad-box': 2,
  'box-builder': 3,
  'liners-box': 1,
};

const editSliceLengthObj = {
  'pad-box': 3,
  'box-builder': 3,
  'tampon-box': 3,
  'liners-box': 1,
};

// define box builder pad indexes so we can check them in the efficient arr

const boxBuilderPadIndexes = [3, 4];

const longPadIndex = 5;

const bbLinerIdx = 6;

const isIncrementB = process.env.GATSBY_TEST_B === 'true';

// TODO Big of all of using objects with named prods / variants so that we can be more obvious about what our indexes mean
export const generateEfficientQuanArr = async (origTotalsArr, prodBox, pvnb) => {
  // 1. duplicate origTotalsArr parameter length with just 0's and add to modify for efficient quantities

  const efficientSubTotalsQuanArr = origTotalsArr.map(() => 0);

  origTotalsArr
    .slice(0, origTotalsArr.length - sliceLengthObj[prodBox.sku])
    .forEach((total, idx) => {
      if (prodBox.sku === 'box-builder' && idx === longPadIndex) {
        efficientSubTotalsQuanArr[idx] = total / 12;
      } else if (prodBox.sku === 'box-builder' && boxBuilderPadIndexes.includes(idx)) {
        if (total > 0 && total % 12 === 0) {
          efficientSubTotalsQuanArr[idx + 4] = total / 12;
          efficientSubTotalsQuanArr[idx] = 0;
        } else if (total > 0 && total % 12 >= 1) {
          efficientSubTotalsQuanArr[idx + 4] = Math.floor(total / 12);
          // TODO change this
          efficientSubTotalsQuanArr[idx] = (total % 12) / 4;
        }
      } else if (prodBox.sku === 'box-builder' && idx === bbLinerIdx) {
        if (total > 0 && total % 24 === 0) {
          efficientSubTotalsQuanArr[idx + 3] = total / 24;
          efficientSubTotalsQuanArr[idx] = 0;
        } else if (total > 0 && total % 24 !== 0) {
          if (isIncrementB) {
            efficientSubTotalsQuanArr[idx] = total / 8;
          } else {
            efficientSubTotalsQuanArr[idx + 3] = Math.floor(total / 24);
            efficientSubTotalsQuanArr[idx] = (total % 24) / 8;
          }
        }
        // ** note -- pvnb is only used if it's not the box builder or pads
      } else efficientSubTotalsQuanArr[idx] = total / getIncrement(idx, pvnb);
    });

  return efficientSubTotalsQuanArr;
};

export const generateEfficientQuanArrSubscriptionMod = async (origTotalsArr, prodBox, pvnb) => {
  // 1. duplicate origTotalsArr parameter length with just 0's and add to modify for efficient quantities

  const efficientSubTotalsQuanArr = origTotalsArr.map(() => 0);

  // This needs to take into account the fact that the box builder is the only actual one we care about when editing
  origTotalsArr
    .slice(0, origTotalsArr.length - editSliceLengthObj[prodBox.sku])
    .forEach((total, idx) => {
      if (
        (prodBox.sku === 'box-builder' || prodBox.sku === 'pad-box') &&
        (boxBuilderPadIndexes.includes(idx) || idx === longPadIndex)
      ) {
        if (idx === longPadIndex) {
          efficientSubTotalsQuanArr[idx] = total / 12;
        } else if (total > 0 && total % 12 === 0) {
          efficientSubTotalsQuanArr[idx + 4] = total / 12;
          efficientSubTotalsQuanArr[idx] = 0;
        } else if (total > 0 && total % 12 >= 1) {
          efficientSubTotalsQuanArr[idx + 4] = Math.floor(total / 12);
          efficientSubTotalsQuanArr[idx] = (total % 12) / 4;
        }
      } else if (prodBox.sku === 'liners-box') {
        if (total > 0 && total % 24 === 0) {
          // these idx + etc are very janky, would be nice to refactor
          // This length - 1 below only accounts for the fact that the 24-pack of liners happens to be the last item in the array
          // also v janky, refactoring recommended
          efficientSubTotalsQuanArr[efficientSubTotalsQuanArr.length - 1] = total / 24;
          efficientSubTotalsQuanArr[idx] = 0;
        } else if (total > 0 && total % 24 >= 1) {
          efficientSubTotalsQuanArr[efficientSubTotalsQuanArr.length - 1] = Math.floor(total / 24);
          efficientSubTotalsQuanArr[idx] = (total % 24) / 8;
        }
      } else if (prodBox.sku === 'box-builder' && idx === bbLinerIdx) {
        if (total > 0 && total % 24 === 0) {
          efficientSubTotalsQuanArr[idx + 3] = total / 24;
          efficientSubTotalsQuanArr[idx] = 0;
        } else if (total > 0 && total % 24 >= 1) {
          efficientSubTotalsQuanArr[idx + 3] = Math.floor(total / 24);
          efficientSubTotalsQuanArr[idx] = (total % 24) / 8;
        }
        // ** note -- pvnb is only used if it's not the box builder or pads
      } else efficientSubTotalsQuanArr[idx] = total / getIncrement(idx, pvnb);
    });

  return efficientSubTotalsQuanArr;
};
