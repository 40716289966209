import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Box, Flex, Select, Text } from 'theme-ui';
import { keyframes } from '@emotion/react';
import AlertCircle from '~/assets/images/icons/alert-circle.svg';
import { DeliverySelectionDropdown } from '../Generic/DeliverySelectionDropdown';

const Swing = keyframes({
  from: { transform: 'rotateZ(-10deg) translateX(2.5px)' },
  to: { transform: 'rotateZ(10deg) translateX(-2.5px)' },
});

export const DeliverySelection = ({
  intervals,
  onIntervalChange,
  selectedInterval,
  showDeliveryError,
  promoIsLive,
}) => {
  const selectedIntervalName = selectedInterval?.name;
  const promoMessage =
    selectedIntervalName === 'Buy Once (Regular Price)'
      ? promoIsLive?.nonSubPromoMessage
      : promoIsLive?.subPromoMessage;

  const promoImage = promoIsLive?.promoImage?.gatsbyImageData;
  return (
    <Flex
      sx={{
        p: ['2rem 1.5rem', '2rem 1.5rem', '0'],
        mb: [0, 0, '3rem'],
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          mt: '-1rem',
        }}
      >
        <Text
          sx={{
            fontSize: ['1.4rem', '1.4rem', '1.6rem'],
            fontWeight: 700,
            color: 'darkTan',
            width: 'fit-content',
            mr: ['2rem', '2rem', '0.5rem'],
            mt: '1rem',
          }}
        >
          DELIVERY:
        </Text>
        <Flex
          sx={{
            width: '65%',
            minWidth: '22rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: '1rem',
            svg: {
              fill: 'darkTan',
            },
            div: {
              width: '100%',
            },
          }}
        >
          <DeliverySelectionDropdown
            onChange={onIntervalChange}
            selectedIntervalName={selectedIntervalName}
            options={intervals}
          />
        </Flex>
      </Flex>
      {!selectedInterval && showDeliveryError && (
        <Flex
          sx={{
            width: '100%',
            padding: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            border: '1px solid #CF2029',
            background: '#FBE6E7',
            mt: ['1rem', '1rem', '2rem'],
          }}
        >
          <AlertCircle />
          <Text sx={{ fontSize: '1.3rem', fontWeight: 700, ml: '0.5rem' }}>
            Select delivery frequency in order to proceed.
          </Text>
        </Flex>
      )}
      {promoIsLive && (
        <Flex
          sx={{
            width: '100%',
            padding: '1rem 0',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '5px',
            mt: ['1rem', '1rem', '2rem'],
          }}
        >
          <Flex
            sx={{
              fontWeight: 700,
              borderRadius: '5px',
              border: '1px solid #CF2029',
              color: 'canary',
              backgroundColor: 'alizarinCrimson',
              padding: '1rem',
              width: '100%',
              maxHeight: '5.5rem',
              alignItems: 'center',
            }}
          >
            {promoImage && (
              <Box
                sx={{
                  animation: `${Swing} 1.5s ease-in-out alternate infinite`,
                  width: '20%',
                  maxHeight: '4.5rem',
                  mr: '0.5rem',
                }}
              >
                <GatsbyImage
                  image={promoImage}
                  objectFit="contain"
                  imgStyle={{ maxHeight: '4.5rem', objectFit: 'contain' }}
                  style={{ width: '100%', maxHeight: '4.5rem' }}
                />
              </Box>
            )}
            <Text
              sx={{
                fontSize: 'calc(min(1.25rem, 4vw))',
                width: promoImage ? '90%' : '100%',
                textAlign: 'center',
                display: 'inline-block',
              }}
            >
              {promoMessage}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
