import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Flex } from 'theme-ui';

const Accordion = ({
  header,
  render = () => null,
  isExpanded,
  onToggle = () => {},
  forwardSX = {},
  headerSx = {},
}) => {
  const headerRef = useRef();
  const contentRef = useRef();
  const [height, setHeight] = useState('7rem');

  useEffect(() => {
    const headerHeight = 70;
    const contentHight = contentRef.current?.clientHeight ?? 0;

    setHeight(isExpanded ? `${headerHeight + contentHight}px` : `${headerHeight}px`);
  }, [isExpanded]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        borderBottom: '2px solid transparent',
        borderBottomColor: 'alizarinCrimson',
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden',
        ...forwardSX,
      }}
      style={{ height }}
    >
      <Button
        ref={headerRef}
        type="button"
        className="c-accordion__header"
        onClick={() => onToggle()}
        variant="clear"
        sx={{
          display: 'flex',
          width: '100%',
          minHeight: '7rem',
          maxHeight: '7rem',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          textTransform: 'uppercase',
          fontSize: '1.8rem',
          fontWeight: 'bold',
          color: 'alizarinCrimson',
          padding: '1.5rem 0 !important',
          border: 0,
          ...headerSx,
        }}
      >
        {header}

        <Box
          sx={{ width: '3rem', height: '3rem' }}
          className={`c-accordion__header-icon ${isExpanded ? 'open' : 'collapsed'}`}
        >
          <span />
          <span />
        </Box>
      </Button>

      <Box
        sx={{
          '& ul': {
            pl: ['1rem'],
            mt: ['0rem'],
          },

          '& ul > li': {
            listStyleType: '"-"',
            pb: ['0.5rem'],
            pl: ['0.5rem'],
          },
          '& p': {
            mt: 0,
            mb: '1.5rem',
          },
          '& p > a': {
            pb: ['0.5rem'],
            pl: ['0.6rem'],
            fontWeight: 700,
          },
        }}
        ref={contentRef}
      >
        {render()}
      </Box>
    </Flex>
  );
};

export default Accordion;
