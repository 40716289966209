/** @jsx jsx */
import React, { useRef, useState } from 'react';
import { Box, Flex, jsx } from 'theme-ui';
import useBurgerMenu from '~/hooks/graphql/queries/use-burger-menu';
import ContentLink from '~/components/Generic/ContentLink';

const BurgerMenu = () => {
  const { menu } = useBurgerMenu();

  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.pageX - slider.offsetLeft;
    const { scrollLeft } = slider;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    ourRef.current.style.cursor = 'grabbing';
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    ourRef.current.style.cursor = 'default';
  };
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.5;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Flex
        ref={ourRef}
        onMouseDown={handleDragStart}
        onMouseUp={handleDragEnd}
        onMouseMove={handleDrag}
        sx={{
          width: '100%',
          minWidth: ['100%', '100%', '100%'],
          maxWidth: '100vw',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: ['2rem', '3rem', '4rem'],
          py: '2rem',
          borderTop: '2px solid #CF2029',
          borderBottom: '2px solid #CF2029',
          textAlign: 'center',
          a: {
            variant: 'text.menuLink',
            mx: ['max(3rem, 10vw)', '2rem', '2rem'],
          },
          overflow: 'scroll',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            width: 0,
            height: 0,
          },
        }}
      >
        {menu.links.map((link, index) => (
          <ContentLink link={link} key={index} />
        ))}
      </Flex>
    </Box>
  );
};

export default BurgerMenu;
