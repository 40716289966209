/** @jsx jsx */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { Flex, Text, jsx, Box } from 'theme-ui';

const FooterLegal = ({ links }) => (
  <Flex sx={{ width: '100%', justifyContent: ['flex-end'] }}>
    <Box sx={{ maxWidth: ['24rem', 'none'], textAlign: ['right'] }}>
      {links.map(({ page: { name, slug } }) => (
        <Link sx={{ variant: 'text.footerLegal' }} to={`/${slug}/`} key={slug}>
          {name}&nbsp;
        </Link>
      ))}

      <Text sx={{ variant: 'text.footerLegal', whiteSpace: 'pre-line' }}>
        <span>· &copy; </span>
        {new Date().getFullYear()}&nbsp; TPC Inc.&nbsp;
        <br sx={{ display: [null, null, 'none'] }} />
        All Rights Reserved
      </Text>
    </Box>
  </Flex>
);

FooterLegal.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
};

export default FooterLegal;
