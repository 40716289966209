/** @jsx jsx */
import React from 'react';
import { Box, Input, jsx, Label } from 'theme-ui';

const FormInputWithLabel = React.forwardRef(({ name, label, ...props }, ref) => (
  <Box sx={{ position: 'relative' }}>
    <Input
      sx={{
        fontSize: ['13px', null, '16px'],
        letterSpacing: '1px',
        width: '100%',
        paddingLeft: [null, null, '1rem'],
        outline: 'none',
        variant: 'text.body',
      }}
      name={name}
      ref={ref}
      {...props}
    />
    <Label
      htmlFor={name}
      sx={{
        position: 'absolute',
        // top: ['8px', null, '12px'],
        left: '15px',
        opacity: '0',
        letterspacing: '1px',
        pointerEvents: 'none',
      }}
    >
      {label}
    </Label>
  </Box>
));
export default FormInputWithLabel;
