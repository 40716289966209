/** @jsx jsx */
import { useEffect, useState, useContext, useRef } from 'react';
import ReactModal from 'react-modal';
import { Container, jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Nav from '~/components/Nav';
import Footer from '~/components/Footer';
import NotificationSnackBar from '../Notification/Snackbar';
import MessageContext from '~/contexts/MessageContext';
import BurgerMenu from '~/components/Nav/BurgerMenu';
import CartPage from '~/components/Cart/Page';
import TopOfferBanner from '../Generic/TopOfferBanner';
import BuildABoxWidget from '../BuildABoxWidget';
import { BoxBuilderPopUpProvider } from '~/contexts/BoxBuilderPopUpContext';
import { trackCustomEvent } from '~/utils/analytics';

ReactModal.setAppElement('#portal');

const Layout = ({ children, hideWidget = false }) => {
  const [message] = useContext(MessageContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const targetRef = useRef();
  const [targetElement, setTargetElement] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      bgImage: contentfulAsset(title: { eq: "cloud-bg" }) {
        title
        gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 2000)
      }
      mobileBgImage: contentfulAsset(title: { eq: "cloud-bg-mobile" }) {
        title
        gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 768)
      }
    }
  `);
  const { bgImage, mobileBgImage } = data;

  const toggleMenu = () => {
    const thatDocument = typeof document !== 'undefined' ? document : 'undefined';
    const mobileBurger = thatDocument.querySelector('#nav-icon-mobile');
    const desktopBurger = thatDocument.querySelector('#nav-icon-desktop');
    const menu = thatDocument.querySelector('#burger-menu');
    if (menuIsOpen) {
      setMenuIsOpen(false);
      mobileBurger.classList.remove('burger-open');
      desktopBurger.classList.remove('burger-open');
      menu.classList.remove('burger-open');
    } else {
      setMenuIsOpen(true);
      mobileBurger.classList.add('burger-open');
      desktopBurger.classList.add('burger-open');
      menu.classList.add('burger-open');
    }
  };

  useEffect(() => {
    setTargetElement(targetRef.current);
    // Disable password page:
    // const cookie = Cookies.get('password-accepted');

    const isIncrementB = process.env.GATSBY_TEST_B === 'true';
    const eventNameSuffix = isIncrementB ? ' Split Test B' : ' Split Test A';

    if (process.env.GATSBY_TRACK_SPLIT_TEST === 'true')
      trackCustomEvent(`Site Viewed${eventNameSuffix}`);

    return () => clearAllBodyScrollLocks();
  }, []);

  useEffect(() => {
    const thatDocument = typeof document !== 'undefined' ? document : 'undefined';

    if (menuIsOpen) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  }, [menuIsOpen]);

  return (
    <BoxBuilderPopUpProvider>
      <Container
        variant="fullwidth"
        sx={{
          backgroundImage: [
            `url(${getSrc(mobileBgImage)})`,
            `url(${getSrc(mobileBgImage)})`,
            `url(${getSrc(bgImage)})`,
          ],
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
        }}
      >
        <TopOfferBanner />
        <CartPage />
        <Nav menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
        <BurgerMenu menuIsOpen={menuIsOpen} ref={targetRef} toggleMenu={toggleMenu} />
        <main>{children}</main>
        <BuildABoxWidget hideWidget={hideWidget} />
        <NotificationSnackBar message={message} />
        <Footer />
      </Container>
    </BoxBuilderPopUpProvider>
  );
};
export default Layout;
