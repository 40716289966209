/** @jsx jsx */
import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex, jsx, Text, useThemeUI, Button } from 'theme-ui';
import useProductExtraDetails from '~/hooks/components/use-product-extra-details';
import BoxSubLineItem from '../BoxSubLineItem';
import Close from '~/assets/images/icons/close-alizarinCrimson.svg';
import useProduct from '~/hooks/components/use-product';
import BoxBuilderPopUpContext, { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import CartContext from '~/contexts/CartContext';
import { DeliverySelectionDropdown } from '~/components/Generic/DeliverySelectionDropdown';
import { updateStoredTags } from '~/utils/misc/storeTags';

const BoxMainLineItem = ({
  sku,
  slug,
  name,
  orderConfirmed,
  boxTotalPrice,
  localSubItems,
  removeBox,
  isSubscription,
  intervalOptions,
}) => {
  const { theme } = useThemeUI();
  const product = useProduct({ slug });
  const { trackProductClicked } = useAnalytics();
  const path = sku.includes('|') ? 'bundles' : 'products';
  const url = `/${path}/${slug}/`;
  const [image, setImage] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { details } = useProductExtraDetails({ sku, slug });
  const { openModal } = useBoxBuilder();
  const [, setCartIsOpen] = useContext(CartContext);

  const subDiscount = Number(boxTotalPrice) - Number(boxTotalPrice) * 0.1;

  const { setDynamicButton, setSelectedInterval, selectedInterval } =
    useContext(BoxBuilderPopUpContext);

  useEffect(() => {
    if (details) {
      const { mainImage } = details;
      setImage(mainImage);
    }
  }, [details]);

  useEffect(() => {
    if (!localSubItems.length) removeBox();
  }, [localSubItems]);

  const handleClick = async (e) => {
    const newInterval = intervalOptions.find((s) => s.name === e.target.value) ?? {
      name: 'Buy Once (Regular Price)',
    };
    setSelectedInterval(newInterval);
    setDynamicButton(true);
  };
  return localSubItems.length ? (
    <Flex
      sx={{
        flexDirection: 'row',
        fontWeight: 700,
        mb: '0.5rem',
        flexWrap: 'wrap',
      }}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexGrow: 1,
          px: orderConfirmed ? 0 : '2rem',
        }}
      >
        {image && (
          <Box
            sx={{
              minWidth: '8rem',
              maxWidth: '8rem',
              position: 'relative',
              mr: orderConfirmed ? '30px' : 0,
            }}
          >
            <Link to={url} onClick={() => trackProductClicked({ product })}>
              <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
            </Link>
          </Box>
        )}
        <Flex
          sx={{
            flexDirection: 'column',
            ml: '20px',
            color: orderConfirmed ? theme.colors.alizarinCrimson : theme.colors.canary,
            flexGrow: 1,
            svg: {
              fill: 'darkTan',
            },
          }}
        >
          <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Text
              sx={{
                fontSize: '1.4rem',
                textTransform: 'uppercase',
                mb: '11px',
                color: theme.colors.alizarinCrimson,
              }}
            >
              {name}
            </Text>
            {!orderConfirmed && (
              <Button
                sx={{
                  variant: 'buttons.removeBox',
                  width: '1.5rem',
                  height: '1.5rem',
                  padding: '0 !important',
                  svg: { width: '100%', height: '100%' },
                }}
                onClick={() => {
                  updateStoredTags(null, {
                    tagKey: 'preBuildBox',
                  });
                  removeBox();
                }}
              >
                <Close />
              </Button>
            )}
          </Flex>
          {!orderConfirmed ? (
            <DeliverySelectionDropdown
              onChange={handleClick}
              selectedIntervalName={selectedInterval?.name}
              options={intervalOptions}
              showPlaceholder={false}
            />
          ) : (
            <Text sx={{ fontSize: ['1rem', '1.2rem'], color: 'alizarinCrimson' }}>
              {isSubscription
                ? `Every ${localSubItems[0].subscriptionLineItems[0].interval} (Save 10%)`
                : 'Buy Once (Regular Price)'}
            </Text>
          )}
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: isEditing ? '1rem' : 0,
              mt: '1rem',
            }}
          >
            <Text
              sx={{
                fontWeight: 700,
                textAlign: 'left',
                fontSize: '13px',
                color: 'alizarinCrimson',
              }}
            >
              <Text sx={{ textDecoration: isSubscription ? 'line-through' : 'none' }}>
                ${Number.parseFloat(boxTotalPrice).toFixed(2)}
              </Text>
              {isSubscription && (
                <Text sx={{ color: 'discountGreen', marginLeft: '0.75rem' }}>
                  ${Number.parseFloat(subDiscount).toFixed(2)}
                </Text>
              )}
            </Text>
            <Button
              onClick={() => {
                if (orderConfirmed) {
                  setIsEditing((prev) => !prev);
                  return;
                }
                setCartIsOpen(false);
                openModal();
              }}
              sx={{
                borderWidth: '1px',
                fontSize: '12px',
                backgroundColor: 'canary',
                minWidth: '56px',
                width: '56px',
                height: '34px',
              }}
            >
              {!orderConfirmed ? <Text>Edit</Text> : <Text>{isEditing ? 'X' : 'View'}</Text>}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {orderConfirmed && (
        <Flex
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            flexGrow: 1,
            minWidth: '100%',
            p: '2rem',
            display: isEditing ? 'block' : 'none',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              ml: '100px',
              flexGrow: 1,
            }}
          >
            {_.map(localSubItems, (li, idx) => (
              <BoxSubLineItem
                key={li.id}
                id={li.id}
                sku={li.variant && li.variant.sku}
                slug={li.variant && li.variant.slug}
                name={li.variant && li.variant.name}
                options={li.variant && li.variant.optionsText}
                quantity={li.quantity}
                displayAmount={li.displayAmount}
                singleDisplayAmount={li.singleDisplayAmount}
                orderConfirmed={orderConfirmed}
                isUpdating={isUpdating}
                setIsUpdating={setIsUpdating}
                idx={idx}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  ) : null;
};

export default BoxMainLineItem;
