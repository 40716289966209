import React from 'react';
import { Button, Flex, Text } from 'theme-ui';
import useWindowSize from '~/hooks/components/use-window-size';

export const TabSelector = ({ isThisSelected, tab, index, setSelectedTab }) => {
  const { width } = useWindowSize();
  return (
    <Flex
      as={Button}
      sx={{
        width: '33.33%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isThisSelected ? 'canary' : 'lightPurple',
        borderColor: 'alizarinCrimson',
        borderStyle: 'solid',
        borderRadius: 0,
        borderTopWidth: [0, 0, '0.2rem'],
        borderTopLeftRadius: [index === 0 ? '2rem' : 0, 0, index === 0 ? '1rem' : 0],
        borderTopRightRadius: [index === 2 ? '2rem' : 0, 0, index === 2 ? '1rem' : 0],
        borderLeftWidth: [index === 1 ? '0.2rem' : 0, index === 1 ? '0.2rem' : 0, '0.2rem'],
        borderRightWidth: [
          index === 1 ? '0.2rem' : 0,
          index === 1 ? '0.2rem' : 0,
          index === 2 ? '0.2rem' : 0,
        ],
        borderBottomWidth: '0.2rem',
        borderBottomLeftRadius: [0, 0, index === 0 ? '1rem' : 0],
        borderBottomRightRadius: [0, 0, index === 2 ? '1rem' : 0],
        flexDirection: ['column', 'column', 'row'],
        svg: {
          height: ['2rem', '2rem', '2.5rem'],
          width: ['2rem', '2rem', '2.5rem'],
        },
      }}
      onClick={() => {
        setSelectedTab(index + 1);
      }}
    >
      {tab?.icon}
      <Text
        sx={{
          color: 'alizarinCrimson',
          textAlign: 'center',
          fontSize: ['1.2rem', '1.2rem', 'min(1.8rem,1.75vw)'],
          mt: ['0.5rem', '0.5rem', 0],
          ml: [0, 0, '0.5rem'],
        }}
      >
        {tab.title}
      </Text>
    </Flex>
  );
};
