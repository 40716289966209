import { graphql, useStaticQuery } from 'gatsby';

const useFooterQuery = () => {
  const data = useStaticQuery(
    graphql`
      query FooterQuery($locale: String) {
        august: contentfulNavigationBar(
          slug: { eq: "footer-august" }
          node_locale: { eq: $locale }
        ) {
          links {
            type
            text
            url
            page {
              name
              slug
            }
          }
        }

        help: contentfulNavigationBar(slug: { eq: "footer-help" }, node_locale: { eq: $locale }) {
          links {
            type
            text
            url
            page {
              name
              slug
            }
          }
        }

        socials: contentfulNavigationBar(
          slug: { eq: "footer-socials" }
          node_locale: { eq: $locale }
        ) {
          links {
            type
            text
            icon {
              svg {
                content
              }
            }
            url
            page {
              name
              slug
            }
          }
        }

        legal: contentfulNavigationBar(slug: { eq: "footer-legal" }, node_locale: { eq: $locale }) {
          links {
            page {
              name
              slug
            }
          }
        }
      }
    `
  );

  return data;
};

export default useFooterQuery;
