/** @jsx jsx */
import React, { useState, useContext } from 'react';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Flex, jsx, Text, Button } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import MessageContext from '~/contexts/MessageContext';
import LineItem from '../LineItem';
import { CartSpinner } from '../CartSpinner/CartSpinner';

const DonateABox = ({
  donationBoxInCart,
  orderConfirmed,
  setOutOfStock,
  NonSubscriptionItems,
  intervalOptions,
  setDonationPromoMessage,
}) => {
  const [, setMessage] = useContext(MessageContext);
  const { addToCart, removeFromCart } = useCart();
  const [isLoading, setIsoading] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      contentfulCatalog(slug: { eq: "catalog" }) {
        collections {
          name
          products {
            ... on ContentfulProduct {
              slug
              sku
              price
              mainImage {
                id
                title
                gatsbyImageData(width: 80)
              }
              subtitle
              subPromoMessage
              shortDescription
              nonSubPromoMessage
              widgetPromoStartTime
              widgetPromoEndTime
            }
          }
        }
      }
    }
  `);

  const boxes = data.contentfulCatalog.collections.filter(
    (collection) => collection.name === 'Donation Boxes'
  )[0]?.products;
  const box = boxes.find((b) => b.sku === 'donation-box-5');
  const addBox = async () => {
    setIsoading(true);
    try {
      await addToCart({
        sku: box.sku,
        quantity: 1,
      });
      setTimeout(() => {
        setIsoading(false);
      }, 50);
    } catch (error) {
      setMessage('error adding box to cart');
      setIsoading(false);
    }
  };

  const { subtitle, nonSubPromoMessage, subPromoMessage } = box;

  setDonationPromoMessage(
    new Date(box.widgetPromoStartTime).getTime() < new Date().getTime() &&
      new Date(box.widgetPromoEndTime).getTime() > new Date().getTime()
      ? subPromoMessage
      : subtitle
  );

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <Flex
        sx={{
          width: '100%',
          flexDirection: 'column',
        }}
      >
        <Text sx={{ fontSize: '1.75rem', mt: '1rem', textTransform: 'uppercase' }}>Impact</Text>
        {isLoading ? (
          <CartSpinner />
        ) : !donationBoxInCart ? (
          <Flex>
            <Flex
              sx={{
                width: '100%',
                justifyContent: ['center', 'center'],
                mt: ['0.8rem'],
              }}
            >
              <Flex sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Flex sx={{ alignItems: 'center' }}>
                  <GatsbyImage
                    image={box.mainImage.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                  <Flex sx={{ flexDirection: 'column', ml: '2rem' }}>
                    <Text sx={{ fontSize: '1.5rem', textTransform: 'uppercase', mb: '1.1rem' }}>
                      Donation Box
                    </Text>
                    <Text sx={{ fontSize: '1.25rem' }}>${box.price.toFixed(2)}</Text>
                  </Flex>
                </Flex>
                <Flex
                  sx={{
                    width: '20%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: 'canary',
                      borderRadius: '11px',
                      p: '1rem 2rem !important',
                      fontSize: '1.5rem',
                    }}
                    onClick={addBox}
                  >
                    Add
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <>
            <Box sx={{ mx: '-2rem', my: '-0.5rem' }}>
              <LineItem
                id={donationBoxInCart.id}
                lineItem={donationBoxInCart}
                variant={donationBoxInCart.variant}
                itemPromos={donationBoxInCart.adjustments.find(
                  (promo) => !!promo.label?.includes('Free')
                )}
                sku={donationBoxInCart.variant && donationBoxInCart.variant.sku}
                slug={donationBoxInCart.variant && donationBoxInCart.variant.slug}
                name={donationBoxInCart.variant && donationBoxInCart.variant.name}
                options={donationBoxInCart.variant && donationBoxInCart.variant.optionsText}
                quantity={donationBoxInCart.quantity}
                displayAmount={donationBoxInCart.displayAmount}
                singleDisplayAmount={donationBoxInCart.singleDisplayAmount}
                orderConfirmed={orderConfirmed}
                removeFromCart={removeFromCart}
                setOutOfStock={setOutOfStock}
                NonSubscriptionItems={NonSubscriptionItems}
                intervalOptions={intervalOptions}
                setDonationBoxLoading={setIsoading}
              />
            </Box>
          </>
        )}
        <Flex
          sx={{
            width: '100%',
            flexDirection: 'column',
            my: ['0.5rem'],
            color: 'alizarinCrimson',
            fontSize: [4, 5, 5],
          }}
        >
          <Text sx={{ fontWeight: 700, textTransform: 'uppercase', mb: '10px' }}>
            About your donation
          </Text>

          <Text sx={{ fontWeight: 400 }}>{box?.shortDescription}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

DonateABox.propTypes = {};

export default DonateABox;
