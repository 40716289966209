import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { useEffect, useState } from 'react';

export const useIsLoggedIn = () => {
  const { getToken } = useAuth();
  const { loadUser } = useUser();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggingError, setLoggingError] = useState(null);

  useEffect(() => {
    const checkStatus = async () => {
      try {
        await getToken();
        loadUser();
        setIsLoggedIn(true);
        setLoggingError(null);
      } catch (error) {
        setLoggingError(error);
      }
    };
    checkStatus();
  }, []);

  return { isLoggedIn, loggingError };
};
