/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { useState, useEffect } from 'react';
import _ from 'lodash';
import { jsx, Flex } from 'theme-ui';
import BABWidgetChevron from '~/components/Generic/CollapsiblePanel/BABWidgetChevron';
import WidgetBoxBuilderButtons from './WidgetBoxBuilderButtons';

const WidgetBoxBuilderCard = ({
  category,
  variants,
  setActiveTotalQuantity,
  totalsArr,
  setTotalsArr,
  customBoxItem,
  totArrStartIndex,
  isIncrementB,
}) => {
  const [localTotalsArr, setLocalTotalsArr] = useState(
    variants.map((item) => (customBoxItem ? item.customBoxDefaultQuantity : item.defaultQuantity))
  );

  const [activeLocalQuantity, setActiveLocalQuantity] = useState(
    localTotalsArr.length && localTotalsArr.reduce((a, b) => a + b)
  );

  const updateTotalsArr = (value, index) => {
    const newArr = [...totalsArr];

    if (customBoxItem) {
      newArr[index + totArrStartIndex] = value;
      setTotalsArr(newArr);
    } else {
      newArr[index] = value;
      setTotalsArr(newArr);
    }
  };

  const updateLocalTotalsArr = (value, index) => {
    const newArr = [...localTotalsArr];
    newArr[index] = value;
    setLocalTotalsArr(newArr);
  };

  // This sets the total quantity
  useEffect(() => {
    setActiveTotalQuantity(totalsArr.length && totalsArr.reduce((a, b) => a + b));
  }, [totalsArr]);

  useEffect(() => {
    setActiveLocalQuantity(localTotalsArr.length && localTotalsArr.reduce((a, b) => a + b));
  }, [localTotalsArr]);

  return (
    <BABWidgetChevron title={category.title} variantTotal={activeLocalQuantity}>
      <Flex
        sx={{
          flexDirection: 'column',
          color: 'alizarinCrimson',
          fontStyle: 'italic',
          fontSize: ['15px', '15px', '18px'],
          width: '100%',
          borderWidth: [0, 0, '0 0.1rem 0.1rem 0.1rem'],
          borderColor: 'chatelle',
          borderStyle: 'solid',
          mb: [0, 0, '2rem'],
        }}
      >
        {_.map(
          variants.filter((v) => v.slug !== 'liners-original-24'),
          (item, index) => (
            <WidgetBoxBuilderButtons
              key={`${item.sku}+${item.displayName}-button-${index}`}
              id={`${item.sku}+${item.displayName}-buttons`}
              increment={isIncrementB ? item.incrementB : item.increment}
              defaultQuantity={
                customBoxItem && totalsArr?.length
                  ? totalsArr[index + totArrStartIndex]
                  : item.defaultQuantity
              }
              category={category}
              variant={item.displayName}
              variantImage={item.variants[0].mainImage}
              maxQuantity={96}
              localTotalsArr={localTotalsArr}
              index={index}
              updateTotalsArr={updateTotalsArr}
              updateLocalTotalsArr={updateLocalTotalsArr}
              isIncrementB={isIncrementB}
            />
          )
        )}
      </Flex>
    </BABWidgetChevron>
  );
};

export default WidgetBoxBuilderCard;
