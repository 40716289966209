import React from 'react';
import { Box } from 'theme-ui';

export const DividerLine = ({ height, color }) => (
  <Box
    sx={{
      height,
      width: '100%',
      backgroundColor: color,
      my: '1rem',
    }}
  />
);
