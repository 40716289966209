import React, { useState } from 'react';
import { Box, Flex, Grid, Text } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import RichTextRenderer from '../Content/RichText';
import Accordion from '../Product/Accordion';

const BuildABoxDetailsTab = () => {
  const {
    content: { benefitsIcons, benefits, images, details, detailSections },
  } = useBoxBuilder();
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);

  return (
    <Box
      sx={{
        height: ['calc(100% - 7.5rem)', '100%'],
        color: 'darkTan',
        p: ['2rem 4rem 4rem', '3rem 4rem 4rem'],
        justifyItems: [null, 'center'],
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          width: 0,
        },
      }}
    >
      <Grid
        sx={{
          gap: '10px',
          width: '100%',
          fontWeight: 'bold',
          marginBottom: ['2rem', '3rem'],
          gridTemplateColumns: ['1fr', null, 'repeat(3, 1fr)'],
        }}
      >
        {benefits.map((benefit, index) => (
          <Flex sx={{ alignItems: 'center' }} key={index}>
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '5rem',
                height: '5rem',
                borderRadius: '100%',
                border: '1.5px solid transparent',
                borderColor: 'darkTan',
                marginRight: '10px',
                img: {
                  width: '2.5rem',
                  height: '2.5rem',
                },
              }}
            >
              <img src={benefitsIcons[index]?.svg.dataURI} alt="" />
            </Flex>

            <Text sx={{ textTransform: 'uppercase', fontSize: 4 }} as="p">
              {benefit}
            </Text>
          </Flex>
        ))}
      </Grid>

      <Grid
        sx={{
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '1rem',
          marginBottom: ['2rem', '3rem'],
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '6px',
          },
        }}
      >
        {images.map((image) => (
          <Box
            sx={{
              width: '100%',
              aspectRatio: '1 / 1',
            }}
          >
            <GatsbyImage image={image.gatsbyImageData} alt={image.title ?? ''} />
          </Box>
        ))}
      </Grid>

      {details && (
        <Box
          sx={{
            fontSize: [4, 7],
            marginBottom: ['2rem', '3rem'],
            a: { letterSpacing: 0 },
          }}
        >
          <RichTextRenderer richText={details} />
        </Box>
      )}

      <Box sx={{ 'h1, h2, h3, h4, h5, h6': { marginTop: 0, fontSize: [7, 7, 7] } }}>
        {detailSections.map((section, index) => (
          <Accordion
            header={section.title}
            headerSx={{
              fontSize: [null, 7],
            }}
            isExpanded={index === activeAccordionIndex}
            onToggle={() => {
              setActiveAccordionIndex(index === activeAccordionIndex ? -1 : index);
            }}
            render={() => (
              <Box
                sx={{
                  fontSize: [4, 6],
                  paddingY: '1rem',
                  a: {
                    pl: '0 !important',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: section.body.childMarkdownRemark.html }}
              />
            )}
            key={index}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BuildABoxDetailsTab;
