/** @jsx jsx */
import { Flex, jsx, Text } from 'theme-ui';

const WidgetBoxSubLineItem = ({ name, quantity }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: ['flex-start', 'center', 'center'],
        fontWeight: 400,
        flexGrow: 1,
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row'],
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexGrow: 1,
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'flex-start',
            color: 'alizarinCrimson',
            mb: '1rem',
          }}
        >
          <Text sx={{ fontSize: '1.2rem', fontWeight: 700, color: 'alizarinCrimson' }}>
            {quantity} x&nbsp;
          </Text>
          <Text
            sx={{
              fontSize: '1.2rem',
              justifyContent: 'flex-start',
              color: 'alizarinCrimson',
              fontWeight: 700,
            }}
          >
            {name.split('(')[0]}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WidgetBoxSubLineItem;
