export const updateStoredTags = (addTag = null, removeTag = null, updateTag = null) => {
  if (!localStorage.getItem('storedTags')) localStorage.setItem('storedTags', JSON.stringify({}));

  const storedTags = JSON.parse(localStorage.getItem('storedTags'));
  if (addTag) {
    const { tagKey, tagValue } = addTag;
    storedTags[tagKey] = tagValue;
  }
  if (removeTag) {
    const { tagKey } = removeTag;
    delete storedTags[tagKey];
  }
  if (updateTag) {
    const { tagKey, tagValue } = updateTag;
    if (storedTags[tagKey]) storedTags[tagKey] = { ...storedTags[tagKey], edited: tagValue.edited };
  }
  localStorage.setItem('storedTags', JSON.stringify(storedTags));
};

export const getallStoredTags = () =>
  localStorage.getItem('storedTags') ? JSON.parse(localStorage.getItem('storedTags')) : {};

export const clearStoreTags = () => {
  localStorage.getItem('storedTags') ? localStorage.removeItem('storedTags') : null;
};
