/** @jsx jsx */
import React, { useEffect } from 'react';
import { Flex, jsx } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import BoxMainLineItem from '../BoxMainLineItem';
import { customBoxSkus } from '~/utils/GetSkus';

const BoxItemsContainer = ({
  boxTotalPrice,
  setBoxTotalPrice,
  orderConfirmed,
  items,
  mainBoxItem,
  isFetching,
  intervalOptions,
}) => {
  const { modifyCart } = useCart();

  const localSubItems = items.filter((item) => customBoxSkus.includes(item.variant.sku));
  const localSubItemPrices = localSubItems.map((item) => parseFloat(item.price) * item.quantity);

  // check if the main build a box is a subscription or not
  const isBoxSubscription = localSubItems.find((item) => item.subscriptionLineItems?.length > 0);

  useEffect(() => {
    if (localSubItemPrices.length && mainBoxItem) {
      setBoxTotalPrice(
        localSubItemPrices.reduce((a, b) => a + b) +
          parseFloat(mainBoxItem.price) * mainBoxItem.quantity
      );
    }
  }, [localSubItemPrices, mainBoxItem]);

  const removeBox = async () => {
    const removeArr = [{ id: mainBoxItem.id, quantity: 0 }];
    try {
      await localSubItems.map(async (item) => {
        await removeArr.push({ id: item.id, quantity: 0 });
      });
      await modifyCart({ attributes: { lineItemsAttributes: removeArr } });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Flex sx={{ flexDirection: 'column', mb: '1.5rem' }}>
      <BoxMainLineItem
        boxTotalPrice={boxTotalPrice}
        removeBox={removeBox}
        localSubItems={localSubItems}
        orderConfirmed={orderConfirmed}
        sku={mainBoxItem.variant && mainBoxItem.variant.sku}
        slug={mainBoxItem.variant && mainBoxItem.variant.slug}
        name={mainBoxItem.variant && mainBoxItem.variant.name}
        isFetching={isFetching}
        isSubscription={isBoxSubscription}
        intervalOptions={intervalOptions}
      />
    </Flex>
  );
};

export default BoxItemsContainer;
