import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY, {
      stripeAccount: process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT,
    });
  }
  return stripePromise;
};
export default getStripe;
