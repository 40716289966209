/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { useEffect, useState } from 'react';
import { jsx, Text, Flex, Button, Box } from 'theme-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import BloodDropTan from '~/assets/images/icons/BloodDropTan.svg';
import BloodDropTanEmpty from '~/assets/images/icons/BloodDropTanEmpty.svg';
import ArrowPrev from '~/assets/images/icons/Arrow-L-Filled.svg';
import ArrowNext from '~/assets/images/icons/Arrow-R-Filled.svg';

export default function WidgetBoxBuilderButtons({
  defaultQuantity,
  maxQuantity,
  variant,
  category,
  variantImage,
  increment,
  updateTotalsArr,
  updateLocalTotalsArr,
  index: categoryIndex,
  id,
  editSub,
}) {
  const [quantity, setQuantity] = useState(defaultQuantity);

  const onChange = (value) => {
    setQuantity(parseInt(value));

    if (editSub) {
      updateTotalsArr(parseInt(value / increment), categoryIndex);
      updateLocalTotalsArr(parseInt(value / increment), categoryIndex);
    } else {
      updateTotalsArr(parseInt(value), categoryIndex);
      updateLocalTotalsArr(parseInt(value), categoryIndex);
    }
  };

  useEffect(() => {
    setQuantity(defaultQuantity);
  }, [defaultQuantity]);

  const totalDropLength = category.title.toLowerCase() === 'tampons' ? 3 : 5; // How many total drops for each category;

  const emptyDropStart = category.title.toLowerCase() === 'liners' ? 4 : 2; // how many empty drops to start with for each category ~~ Light Tampon (2/3), Day-Pad(2/5), Original(4/5)

  const showArrows =
    document.getElementById(id)?.getBoundingClientRect().width <
    document.getElementById(id)?.scrollWidth;

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        my: '2rem',
        width: 'auto',
        maxHeight: '11rem',
        overflow: 'hidden',
      }}
    >
      <Flex
        sx={{
          width: '20%',
          height: '100%',
          p: '1rem',
          img: {
            objectFit: 'contain !important',
          },
          justifyContent: 'center',
        }}
      >
        <GatsbyImage image={variantImage.gatsbyImageData} sx={{ maxWidth: '7rem' }} />
      </Flex>
      <Flex sx={{ flexDirection: 'column', width: '80%', position: 'relative' }}>
        <Box sx={{ pb: '0.5rem', pr: '0.5rem', mb: '1rem' }}>
          {/* the empty drops increase in chronological order so, for each category(e.g. light, regular, super) the empty drops reduce by 1. */}
          {/* e.g. super Tampons ~~  totalDropLength (3) - dropIndex(2) > emptyDropStart (2) - categoryIndex (0) ~~ all filed drops */}
          {Array.from({ length: totalDropLength }).map((item, dropIndex) =>
            totalDropLength - dropIndex > emptyDropStart - categoryIndex ? (
              <BloodDropTan />
            ) : (
              <BloodDropTanEmpty />
            )
          )}
          <Text
            sx={{
              color: 'darkTan',
              ml: '0.5rem',
              textAlign: 'left',
              fontSize: '1.4rem',
              fontWeight: 700,
            }}
          >
            {variant}
          </Text>
        </Box>
        <Box
          sx={{
            margin: 0,
            position: 'absolute',
            width: '9vw',
            height: '60%',
            display: ['block', 'block', 'none'],
            backgroundImage: 'linear-gradient(to left, #e2dcefc9,#e2dcefc9,#e2dcef9c, #e2dcef4f)',
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
          }}
        />
        <Flex
          sx={{
            width: '100%',
            pr: [0, 0, '2rem'],
          }}
          id="scrollContainer"
        >
          <Button
            onClick={() => {
              document.getElementById(id).scrollBy(-165, 0); // 3 bubble scroll
            }}
            sx={{
              display: ['none', 'none', showArrows ? 'block' : 'none'],
              background: 'none',
              border: 'none',
              left: 0,
              zIndex: 1,
              pl: '0 !important',
              pr: '1.5rem !important',
              svg: {
                width: ' 14px',
              },
            }}
            id="leftArrow"
          >
            <ArrowPrev />
          </Button>
          <Flex
            sx={{
              width: 'auto',
              overflow: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollBehavior: 'smooth',
              '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
              scrollbarWidth: 'none' /* Firefox */,
              '&::-webkit-scrollbar': {
                display: 'none' /* Safari and Chrome */,
              },
              pr: '1.5rem',
            }}
            id={id}
          >
            {Array.from({ length: maxQuantity / increment + 1 }).map((item, idx) => (
              <Button
                onClick={(e) => {
                  onChange(idx * increment);
                  const buttonPosition = e.target.getBoundingClientRect().right;
                  if (
                    buttonPosition + 10 >
                    document.getElementById(id).getBoundingClientRect().right
                  ) {
                    document.getElementById(id).scrollBy(165, 0); // 3 bubble scroll
                  }
                }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '1.4rem',
                  minHeight: '4rem',
                  minWidth: '4rem',
                  maxHeight: '4rem',
                  maxWidth: '4rem',
                  mr: '1.5rem',
                  p: 0,
                  border: '2px solid #CF2029',
                  borderRadius: '50%',
                  backgroundColor: quantity === idx * increment ? 'canary' : 'transparent',
                  scrollSnapAlign: 'start',
                  '-webkit-tap-highlight-color': 'transparent',
                }}
              >
                {idx * increment}
              </Button>
            ))}
          </Flex>

          <Button
            onClick={() => document.getElementById(id).scrollBy(165, 0)} // 3 bubble scroll
            sx={{
              display: ['none', 'none', showArrows ? 'block' : 'none'],
              background: 'none',
              border: 'none',
              right: 0,
              zIndex: 1,
              pr: '0 !important',
              svg: {
                width: ' 14px',
              },
            }}
            id="rightArrow"
          >
            <ArrowNext />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
