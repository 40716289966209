/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import Slider from 'react-slick';
import { Box, Heading, jsx } from 'theme-ui';
import React from 'react';
import AddonCard from './Card';
import Spacer from '~/components/Generic/Layout/Spacer';
import { DividerLine } from '~/components/Generic/DividerLine/DividerLine';
import useAddons from '~/hooks/components/use-addons';

const CartAddons = ({ itemsInCart = [] }) => {
  const { products } = useAddons();

  const skusInCart = itemsInCart.map((item) => item.variant.sku);

  const addonProducts = products
    // Filter out products that are already in cart
    .map((product) => {
      const updatedVariants = product.variants.map((variant) => {
        const inCart = skusInCart.includes(variant.sku);

        return { ...variant, available: variant.available && !inCart };
      });

      return { ...product, variants: updatedVariants };
    })
    .filter((product) => {
      const availableVariants = product.variants.filter((variant) => variant.available);

      return availableVariants.length > 0;
    });

  if (!addonProducts.length) return null;

  return (
    <>
      <Box>
        <Heading as="h3" variant="h3" sx={{ fontSize: [5, 5, 5], fontWeight: 'bold', mb: '2rem' }}>
          One Time Add-Ons
        </Heading>

        <Slider
          sx={{
            marginBottom: '1.6rem',
            '.slick-dots': {
              bottom: '-3rem',
            },
            '.slick-dots li:not(:last-of-type)': {
              marginRight: 0,
            },
            '.slick-dots li button::before': {
              fontSize: '10px',
            },
            '.slick-dots li button:hover::before': {
              color: 'alizarinCrimson',
            },
            '.slick-dots li.slick-active button::before': {
              color: 'alizarinCrimson',
              opacity: 1,
            },
          }}
          arrows={null}
          dots
          slidesToShow={2.5}
          infinite={false}
        >
          {addonProducts.map((product, index) => (
            <AddonCard product={product} key={product.slug + index} />
          ))}
        </Slider>
      </Box>

      <Spacer height="1.5rem" />

      <DividerLine height="0.1rem" color="chatelle" />
    </>
  );
};

export default CartAddons;
