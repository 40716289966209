import React from 'react';
import { Box, Flex } from 'theme-ui';
import { DeliverySelection } from './DeliverySelection';
import { SubscriptionInfo } from './SubscriptionInfo';
import WidgetBoxBuilderCard from './WidgetBoxBuilderCard';

export const BuildABoxTab = ({
  product,
  intervals,
  setTotalsArr,
  activeTotalPrice,
  activeTotalQuantity,
  setActiveTotalQuantity,
  onIntervalChange,
  prodVariantsNoBox,
  regularPrice,
  selectedInterval,
  sku,
  totalsArr,
  showDeliveryError,
  promoIsLive,
  totalPrice,
}) => {
  const tamponsArr = prodVariantsNoBox?.filter((item) => item?.slug?.split('-')[0] === 'tampon');

  const linersArr = prodVariantsNoBox?.filter((item) => item?.slug?.split('-')[0] === 'liners');

  const padsArr = prodVariantsNoBox?.filter((item) => item?.slug?.split('-')[0] === 'pad');

  const totalBoxArr = [
    { title: 'Tampons', data: tamponsArr, startIndex: 0 },
    { title: 'Pads', data: padsArr.slice(0, 3), startIndex: 3 },
    { title: 'Liners', data: linersArr, startIndex: 6 },
  ];

  const isIncrementB = process.env.GATSBY_TEST_B === 'true';

  return (
    <Flex
      sx={{
        height: '100%',
        flexDirection: 'column',
        overflowY: 'scroll',
        overflowX: 'hidden',
        pb: '10rem',
        '::-webkit-scrollbar': {
          width: 0,
        },
        scrollbarWidth: 'none',
      }}
    >
      <Box sx={{ display: ['block', 'block', 'none'] }} id="deliverySection">
        <DeliverySelection
          intervals={intervals}
          onIntervalChange={onIntervalChange}
          selectedInterval={selectedInterval}
          showDeliveryError={showDeliveryError}
          promoIsLive={promoIsLive}
          totalPrice={totalPrice}
        />
      </Box>
      <Flex sx={{ flexDirection: 'column', width: '100%', mb: [0, 0, '10rem'] }}>
        {totalBoxArr.map((category) => {
          return (
            <WidgetBoxBuilderCard
              customBoxItem
              category={category}
              key={category.data[0].product}
              product={product}
              title={category.data[0].title}
              increment={isIncrementB ? category.data[0].incrementB : category.data[0].increment}
              name="tampons"
              maxQuantity={32}
              variants={category.data}
              regularPrice={regularPrice}
              sku={sku}
              activeTotalPrice={activeTotalPrice}
              activeTotalQuantity={activeTotalQuantity}
              setActiveTotalQuantity={setActiveTotalQuantity}
              totalsArr={totalsArr}
              setTotalsArr={setTotalsArr}
              totArrStartIndex={category.startIndex}
              isIncrementB={isIncrementB}
            />
          );
        })}
      </Flex>
      <Box sx={{ display: ['block', 'block', 'none'] }}>
        <SubscriptionInfo />
      </Box>
    </Flex>
  );
};
