import React from 'react';
import { Select } from 'theme-ui';

export const DeliverySelectionDropdown = ({
  onChange,
  selectedIntervalName,
  options,
  showPlaceholder = true,
  showOneTime = true,
  forwardSX = {},
  hideSubDiscount = false,
}) => {
  return (
    <Select
      sx={{
        width: '100%',
        fontFamily: 'halcom',
        pl: '10px',
        pr: '2.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
        backgroundColor: 'white',
        color: 'darkTan',
        outline: 'none',
        ...forwardSX,
        ':focus': {
          fontSize: '1.3rem',
        },
      }}
      name="interval"
      onChange={(e) => onChange(e)}
    >
      {showPlaceholder ? (
        <option value="" selected={!selectedIntervalName}>
          Select ...
        </option>
      ) : null}
      {showOneTime && (
        <option
          value="Buy Once (Regular Price)"
          selected={selectedIntervalName === 'Buy Once (Regular Price)'}
        >
          Buy Once
        </option>
      )}
      {options.map((i, idx) => (
        <option
          key={`${i.name}-${idx}`}
          value={i.name}
          selected={selectedIntervalName?.toLowerCase() === i.name?.toLowerCase()}
        >
          {/* dont show '(Save 10%) for donation box as those dont have sub discount' */}
          {hideSubDiscount ? i.name.replace(/\([^()]*\)/g, '') : i.name}
        </option>
      ))}
    </Select>
  );
};
