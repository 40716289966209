import { useStaticQuery, graphql } from 'gatsby';
import { useEffect, useState } from 'react';

const useAddons = () => {
  const [products, setProducts] = useState([]);

  // STATIC QUERY: Get all add-on products
  const { collection } = useStaticQuery(graphql`
    query AddonProductsQuery {
      collection: contentfulCollection(slug: { eq: "add-ons" }) {
        products {
          ... on ContentfulProduct {
            slug
            name
            mainImage {
              title
              gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 700)
            }
            optionTypes {
              name
              slug
              presentation
              optionValues {
                name
                slug
                presentation
                optionalRendering
                option_type {
                  slug
                }
              }
            }
            variants {
              name
              sku
              price
              mainImage {
                title
                gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 700)
              }
              optionValues {
                name
                slug
                presentation
                optionalRendering
                option_type {
                  slug
                }
              }
            }
          }
          ... on ContentfulPreBuiltBoxes {
            title
          }
        }
      }
    }
  `);

  // GET AVAILABILITY: Query for all variants availability
  const queryAvailabilities = async (rawProducts) => {
    const skus = rawProducts.reduce(
      (acc, product) => [...acc, ...product.variants.map((v) => v.sku)],
      []
    );

    const availabilityPromises = skus.map((sku) =>
      fetch(
        `${process.env.GATSBY_CHORD_OMS_API_URL || ''}/api/variants/${sku}/stock_availabilities`
      ).then((response) => response.json())
    );

    const availability = await Promise.allSettled(availabilityPromises).then((results) =>
      results.map((r) => [r.value.sku, r.value.in_stock])
    );

    const availabilityMap = Object.fromEntries(availability);

    const productsWithAvailableProp = rawProducts.map((product) => {
      const variantsWithAvailableProp = product.variants.map((variant) => {
        const available = availabilityMap[variant.sku];
        return { ...variant, available };
      });

      const isProductAvailable =
        variantsWithAvailableProp.find((variant) => variant.available) !== undefined;

      return { ...product, variants: variantsWithAvailableProp, available: isProductAvailable };
    });

    setProducts(productsWithAvailableProp);
  };

  // MOUNT-HOOK: Filter and sort products by slug, conditionally add availability data
  useEffect(() => {
    queryAvailabilities(collection.products);
  }, [collection]);

  return { products };
};

export default useAddons;
