/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, jsx } from 'theme-ui';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';

const ContentLink = ({ link }) => {
  const { openModal } = useBoxBuilder();

  const handleLinkClick = ({ destination, url, scrollToSection = null }) => {
    if (scrollToSection) {
      setTimeout(() => {
        const scrollSection = document.getElementById(scrollToSection.trim());
        scrollSection?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
    if (window.analytics) {
      window.analytics.track('Footer Link Clicked', {
        destination,
        url,
      });
    }
  };

  if (link.type === 'openWidget')
    return (
      <Link
        onClick={() => {
          openModal();
        }}
        to="/products/box-builder/?openWidget"
      >
        {link.text}
      </Link>
    );
  if (link.type === 'ScrollToSection')
    return (
      <Link
        onClick={() =>
          handleLinkClick({
            destination: link.page.name,
            url: `/${link.page.slug}`,
            scrollToSection: link.scrollDivId,
          })
        }
        to="/"
      >
        {link.text}
      </Link>
    );

  if (link.type === 'Page')
    return (
      <Link
        onClick={() => handleLinkClick({ destination: link.page.name, url: `/${link.page.slug}` })}
        to={`/${link.page.slug}`}
      >
        {link.text}
      </Link>
    );

  if (link.type === 'Social')
    return (
      <Box
        as={Link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleLinkClick({ destination: link.text, url: link.url })}
        to={link.url}
        dangerouslySetInnerHTML={{ __html: link.icon.svg.content }}
      />
    );

  return (
    <Link
      onClick={() => handleLinkClick({ destination: link.text, url: link.url })}
      to={link.url}
      target={link.type === 'External' ? '_blank' : ''}
    >
      {link.text}
    </Link>
  );
};

export default ContentLink;
