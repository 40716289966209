/** @jsx jsx */
import PropTypes from 'prop-types';
import { Container, jsx, Flex } from 'theme-ui';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';
import { Link } from 'gatsby';

import NavMenuLogo from './Logo';
import NavMenuCartLink from './CartLink';
import UserIcon from '~/assets/images/icons/user-small.svg';

const NavMenu = ({ itemCount, data }) => {
  const { logo } = data;
  const { isLoggedIn } = useAuth();

  return (
    <Container
      variant="fullWidth"
      sx={{
        display: ['flex'],
        width: '100%',
        height: ['80px', '100px'],
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: ['0 15px 0 15px', '20px 50px 20px 20px'],
      }}
    >
      <Link
        aria-label="Link to account page"
        to={isLoggedIn ? '/account' : '/account/signin'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '5.5rem',
          width: '5.5rem',
        }}
      >
        <UserIcon />
      </Link>
      <NavMenuLogo logo={logo} />

      <Flex sx={{ alignItems: 'center' }}>
        <NavMenuCartLink itemCount={itemCount} />
      </Flex>
    </Container>
  );
};

NavMenu.propTypes = {
  itemCount: PropTypes.number,
};

export default NavMenu;
