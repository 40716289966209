import { useLocation } from '@reach/router';
import useSiteMetadataQuery from '~/hooks/graphql/queries/use-site-metadata';

/*
 *  This is the order of precedence used by `useMetadata` to determine the page's metadata:
 *
 *  1. First, we look for a pageMetadata entry in Contentful with a matching path.
 *  2. Second, we look at the props passed to this component.
 *  3. Third, we look at the default pageMetadata entry in Contentful.
 */

const useMetadata = ({ description, image, title }) => {
  const { pathname } = useLocation();
  const { metadata, site } = useSiteMetadataQuery();

  const getPageMetadata = (meta) => {
    if (!meta) return {};

    return {
      description: meta.description.description,
      image: meta.image.file.url,
      path: meta.path,
      title: meta.title,
    };
  };

  const defaultPage = getPageMetadata(metadata.default);
  const matchingPage = getPageMetadata(metadata.pages.find((page) => page.path === pathname));

  const metaTitle = `${matchingPage.title || title || defaultPage.title} | ${
    site.siteMetadata.title
  }`;

  const metaDescription = matchingPage.description || description || defaultPage.description;

  const metaImage = image || matchingPage.image || defaultPage.image;

  const metaUrl = `${site.siteMetadata.siteUrl}${pathname === '/' ? '' : pathname}`;

  return { metaTitle, metaDescription, metaImage, metaUrl };
};

export default useMetadata;
