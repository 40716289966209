import { utils } from '@chordcommerce/gatsby-theme-autonomy';
import { toLocaleCurrency } from '~gatsby-theme-autonomy/utils/formatters';

export const getAllFormatedPromotions = (cart) => {
  const { getAllCartPromotions } = utils;
  const allPromotions = getAllCartPromotions(cart);
  const groupedPromos = allPromotions.reduce((acc, { label, amount, promotionCode, sourceId }) => {
    acc[sourceId] = acc[sourceId] || {
      label,
      amount: 0,
      code: promotionCode ? promotionCode.value : null,
    };
    acc[sourceId].amount += parseFloat(amount);
    return acc;
  }, {});
  return Object.entries(groupedPromos).map(([key, { label, amount, code }]) => ({
    id: key,
    label,
    amount,
    displayAmount: toLocaleCurrency({
      number: amount,
      locale: cart.metadata?.locale || 'en-US',
      currency: cart.currency || 'USD',
    }),
    code: code ? code.toUpperCase() : null,
  }));
};
