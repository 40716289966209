import React, { useEffect } from 'react';
import { Box } from 'theme-ui';

const BuildABoxReviewsTab = () => {
  useEffect(() => {
    if (!window.yotpoWidgetsContainer && !window.yotpo) return;

    if (window.yotpoWidgetsContainer) window.yotpoWidgetsContainer.initWidgets();
    if (window.yotpo) window.yotpo.refreshWidgets();
  }, []);

  return (
    <Box
      sx={{
        height: ['calc(100% - 7.5rem)', '100%'],
        color: 'darkTan',
        p: ['2rem 2rem 4rem', '3rem 4rem 4rem'],
        justifyItems: [null, 'center'],
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          width: 0,
        },
        '> #yotpoReviewsWidget .main-widget .yotpo-reviews-filters': {
          display: 'none',
        },
      }}
    >
      <div
        className="yotpo yotpo-main-widget"
        id="yotpoReviewsWidget"
        data-product-id="box-builder"
        data-name="Box Builder"
      />
    </Box>
  );
};

export default BuildABoxReviewsTab;
