/* eslint-disable no-nested-ternary */
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { Box, Button, Text } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import ArrowRight from '~/assets/images/icons/arrow-right.svg';
import { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import MinimizeIcon from '~/assets/images/icons/minimize.svg';
import CartContext from '~/contexts/CartContext';

export const BoxBuilderIndicator = () => {
  const { isModalOpen, isMinimized, itemCount, openModal, minimizeWidget } = useBoxBuilder();
  const [isCartOpen, setIsCartOpen] = useContext(CartContext);

  const { cart } = useCart();
  const boxInCart = cart?.lineItems?.find((item) => item?.variant?.sku === 'box-builder');

  const handleIndicatorClick = () => {
    boxInCart ? setIsCartOpen(true) : openModal();
  };
  return !boxInCart || (boxInCart && !isCartOpen) ? (
    <Box
      sx={{
        position: 'fixed',
        bottom: isModalOpen ? '-100%' : itemCount > 0 ? '16px' : '-100%',
        left: ['2.5%', '5%', '16px'],
        gridTemplateColumns: ['8rem 1fr', '12rem 1fr'],
        alignItems: 'center',
        width: ['95%', '90%'],
        maxWidth: [
          isMinimized ? '11.5rem' : boxInCart ? '31rem' : '46rem',
          isMinimized ? '16rem' : boxInCart ? '40rem' : '46rem',
          isMinimized ? '16rem' : boxInCart ? '40rem' : '56rem',
        ],
        transition: isMinimized ? 'all 0.3s ease-in-out' : 'all 0.3s 0.3s ease-in-out',
        p: ['1.6rem 2rem', null],
        zIndex: 50,
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'alizarinCrimson',
          borderRadius: '20px',
          boxShadow: 'rgb(230, 252, 92) 5px 5px 0px 0px',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 'max-content',
          maxWidth: [
            isMinimized ? '10rem' : '46rem',
            isMinimized ? '16rem' : '46rem',
            isMinimized ? '16rem' : '56rem',
          ],
          pl: ['10rem', '16rem'],
          transition: isMinimized ? 'all 0.3s ease-in-out' : 'all 0.3s 0.3s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <Button
          onClick={handleIndicatorClick}
          sx={{
            variant: 'buttons.ctaHighlight',
            position: 'relative',
            justifySelf: 'flex-end',
            fontSize: ['14px'],
            px: ['1.6rem !important'],
            transition: isMinimized ? 'all 0.3s ease-in-out' : 'all 0.3s 0.3s ease-in-out',
            '&:hover': {
              boxShadow: 'none',
            },
          }}
        >
          {boxInCart ? (
            <Text>Go To Cart</Text>
          ) : (
            <Text>
              Continue
              <Text as="span" sx={{ display: ['none', null, 'inline'] }}>
                &nbsp;building
              </Text>
              &nbsp;your box
            </Text>
          )}
          <ArrowRight />
        </Button>
      </Box>

      <Button
        aria-label={isMinimized ? 'Open builder' : 'Minimize banner'}
        onClick={minimizeWidget}
        sx={{
          position: 'absolute',
          top: ['-1rem', '50%'],
          right: '-0.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '3rem',
          height: '3rem',
          backgroundColor: 'canary',
          borderRadius: '100%',
          transform: [null, 'translateY(-50%)'],
          opacity: isMinimized ? 0 : 1,
          transition: isMinimized ? 'all 0.3s ease-in-out' : 'all 0.3s 0.3s ease-in-out',
          padding: '0 !important',
          svg: {
            width: '1.6rem',
          },
        }}
      >
        <MinimizeIcon />
      </Button>

      <Box
        sx={{
          position: 'absolute',
          top: '-1.6rem',
          left: '2rem',
          width: ['8rem', '12rem'],
          height: '100%',
        }}
      >
        <Button
          variant="clear"
          disabled={isCartOpen}
          onClick={isMinimized ? handleIndicatorClick : minimizeWidget}
          sx={{ border: 0, position: 'absolute', bottom: [0, '-0.6rem'], left: ['-0.5rem', 0] }}
        >
          <StaticImage src="../../assets/images/Parachute-Box.png" quality={100} />
        </Button>
      </Box>
    </Box>
  ) : null;
};
