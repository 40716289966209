/** @jsx jsx */
import React from 'react';
import { Flex, jsx } from 'theme-ui';
import WidgetBoxLineItem from './WidgetBoxLineItem';

const WidgetBoxItemsContainer = ({
  items,
  mainBoxItem,
  isFetching,
  isSubscription,
  totalsArr,
  setTotalsArr,
}) => {
  const localSubItemSkus = [
    'pad-night',
    'pad-day',
    'tampon-light',
    'tampon-regular',
    'tampon-super',
    'liners-original',
    'pad-day-12',
    'pad-ngt-12',
    'pad-lnr-24',
    'pad-lng-ngt-12',
  ];
  const localSubItems = items.filter((item) => localSubItemSkus.includes(item.sku));

  return (
    <Flex sx={{ flexDirection: 'column', my: '0.5rem', display: ['none', 'none', 'flex'] }}>
      <WidgetBoxLineItem
        localSubItems={localSubItems}
        sku={mainBoxItem && mainBoxItem.sku}
        slug={mainBoxItem && mainBoxItem.slug}
        name={mainBoxItem && mainBoxItem.name}
        isFetching={isFetching}
        isSubscription={isSubscription}
        details={mainBoxItem}
        totalsArr={totalsArr}
        setTotalsArr={setTotalsArr}
      />
    </Flex>
  );
};

export default WidgetBoxItemsContainer;
