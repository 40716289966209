/**
 * Provides easy on-demand configuration for Fat Media's Pixel
 *
 * @param {string} network - Identify the network
 * @param {0 | 1 | TCF ID} consent
 * @param {START | PRODUCT | CATALOG | SEARCH | WISHLIST | CART | CHECKOUT | ORDER  | CONVERSION} pageType
 * @param {string} productId - Product number on product pages
 * @param {string} orderId - ID of purchase transaction
 * @param {string} orderTotal - Value of purchase transaction
 * @param {array | object} lineItems - List of products that were purchased
 */
export const configureFatMedia = ({
  network,
  consent,
  pageType,
  productId,
  orderId,
  orderTotal,
  lineItems,
}) => {
  if (typeof window === 'undefined') return;

  if (network) window.glk_network = network;
  if (consent !== undefined) window.glk_euconsent = consent;
  if (pageType) window.glk_pagetype = pageType;
  if (productId) window.glk_product = productId;
  if (orderId) {
    window.glk_txnid = orderId;
    window.glk_currency = 'USD';
  }
  if (orderTotal) window.glk_txnvalue = orderTotal;
  if (lineItems) window.glk_txnproducts = lineItems;
};
