import useSWR from 'swr';
import axios from 'axios';

const API_URL = process.env.GATSBY_CHORD_OMS_API_URL || '';

const fetcher = (url) => axios.get(url).then((res) => res.data);
const multiFetcher = (urls) => Promise.all(urls.map((url) => fetcher(url)));

const useVariantAvailability = ({ sku }) => {
  const baseURL = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
  const query = `${baseURL}/api/variants/${sku}/stock_availabilities`;
  const { data, error, isLoading } = useSWR(query, fetcher);
  return {
    isAvailable: data ? data.in_stock || data.is_backorderable : true,
    isFetchingAvailability: !error && !data,
    isLoading,
    totalOnHand: data?.total_on_hand > 0 ? data?.total_on_hand : 0,
    error,
  };
};

export const useAllVariantAvailability = ({ product }) => {
  const baseURL = API_URL.endsWith('/') ? API_URL.slice(0, -1) : API_URL;
  const queries = product?.variants?.map((variant) => {
    return `${baseURL}/api/variants/${variant.sku}/stock_availabilities`;
  });
  const { data: allData, error, isLoading } = useSWR(queries, multiFetcher);
  return allData?.map((data) => {
    const stockSKU = product?.variants.find((variant) => variant.sku === data.sku);
    const options = stockSKU?.optionValues?.map((option) => {
      const key = option.option_type[0].slug;
      return {
        [key]: option,
      };
    });
    return {
      sku: data.sku,
      isAvailable: data ? data.in_stock || data.is_backorderable : true,
      isFetchingAvailability: !error && !data,
      isLoading,
      totalOnHand: data?.total_on_hand > 0 ? data?.total_on_hand : 0,
      error,
      ...options?.reduce((result, currentObject) => {
        return { ...result, ...currentObject };
      }, {}),
    };
  });
};

export default useVariantAvailability;
