export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const getQuan = (items, sku) =>
  items.filter((item) => item.sku === sku).length
    ? items.filter((item) => item.sku === sku)[0].quantity
    : 0;

export const getEfficQuan = (items, sku) => {
  let quanTot = 0;

  items
    .filter((i) => i.sku !== 'pad-box' && i.sku !== 'liners-box')
    .forEach((item) => {
      if (
        (item.sku === 'pad-ngt-12' && sku === 'pad-night') ||
        (item.sku === 'pad-day-12' && sku === 'pad-day') ||
        (item.sku === 'pad-lnr-24' && sku === 'liners-original')
      ) {
        quanTot += item.quantity * 3;
      } else if (item.sku === sku) {
        quanTot += item.quantity;
      }
    });
  return quanTot;
};

// eslint-disable-next-line consistent-return
export const getTotArrStartIndex = (coll) => {
  if (coll === 'tampons') {
    return 0;
  }
  if (coll === 'pads') {
    return 3;
  }
  if (coll === 'liners') {
    return 6;
  }
};

export const getSku = (pvnb, index) => pvnb[index].variants[0].sku;

export const getId = (items, sku) =>
  items.filter((item) => item.sku === sku).length
    ? items.filter((item) => item.sku === sku)[0].id
    : null;

export const getInterval = (name) => {
  if (name === 'Every 3 Months') {
    return '3 months';
  }
  return '1 month';
};

export const getCollection = (items, uniqProds, collectionData) => {
  // TODO refactor this, this is janky
  if (items.map((a) => a.sku).includes('liners-box')) {
    return collectionData.linersCollection;
  }
  if (items.map((a) => a.sku).includes('sample-box')) {
    return collectionData.sampleBoxCollection;
  }
  if (uniqProds.length > 1) {
    return collectionData.customBoxCollection;
  }
  if (uniqProds[0] === 'tampon') {
    return collectionData.tamponsCollection;
  }
  if (uniqProds[0] === 'pad') {
    return collectionData.padsCollection;
  }
  return collectionData.linersCollection;
};

export const getArrayFromLineItems = (lineItems) => {
  return lineItems.length
    ? [
        getQuan(lineItems, 'tampon-light'),
        getQuan(lineItems, 'tampon-regular'),
        getQuan(lineItems, 'tampon-super'),
        getEfficQuan(lineItems, 'pad-day'),
        getEfficQuan(lineItems, 'pad-night'),
        getQuan(lineItems, 'pad-lng-ngt-12'),
        getEfficQuan(lineItems, 'liners-original'),
      ]
    : null;
};
