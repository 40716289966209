import React, { useContext, useEffect, useState } from 'react';
import { Button, Flex } from 'theme-ui';
import { useCart } from '@chordcommerce/gatsby-theme-autonomy';
import { navigate } from 'gatsby';
import { BlurryOverlay } from '../Generic/BlurryOverlay';
import CloseIcon from '~/assets/images/icons/close-icon.svg';
import { BuildABoxTab } from './BuildABoxTab';
import { WidgetAddToBag } from './WidgetAddToBag';
import Star from '~/assets/images/icons/Star.svg';
import PopBox from '~/assets/images/icons/PopBox.svg';
import Details from '~/assets/images/icons/Details.svg';
import useLockBodyScroll from '~/hooks/components/use-lock-body-scroll';
import useProductVariants from '~/hooks/components/use-product-variants';
import BuildABoxDetailsTab from './BuildABoxDetailsTab';
import { TabSelector } from './TabSelector';
import useWindowSize from '~/hooks/components/use-window-size';
import BuildABoxReviewsTab from './BuildABoxReviewsTab';
import BoxBuilderPopUpContext, { useBoxBuilder } from '~/contexts/BoxBuilderPopUpContext';
import { customBoxSkus } from '~/utils/GetSkus';
import { getArrayFromLineItems } from '~/utils/account/editSubscription';

const tabArray = [
  { title: 'Your Box', icon: <PopBox /> },
  { title: 'Details', icon: <Details /> },
  { title: 'Reviews', icon: <Star /> },
];

export const BuildABoxModal = ({ product }) => {
  const { variants, subscription } = product;
  const { intervals } = subscription;
  const { totalsArr, setTotalsArr, selectedInterval, setSelectedInterval } =
    useContext(BoxBuilderPopUpContext);

  //----------------------------
  // Use Hooks
  //----------------------------
  const { cart } = useCart();
  const { openModal, isModalOpen, closeModal, setItemCount } = useBoxBuilder();
  const { currentVariant, selectVariant } = useProductVariants(variants);
  const { height } = useWindowSize();
  useLockBodyScroll(isModalOpen);

  //----------------------------
  // Product Processing logic
  //----------------------------
  const { regularPrice, sku } = currentVariant;
  const buildABoxCollection = product.collection.find((c) => c.slug === 'custom');
  const { promoImage, nonSubPromoMessage, subPromoMessage } = product;
  const { increment, maxQuantity } = buildABoxCollection;
  const prodVariants = buildABoxCollection.products;
  const prodVariantsNoBox = buildABoxCollection.products.filter(
    (prod) => prod.displayName !== 'box'
  );
  const prodBox = buildABoxCollection.products.filter((prod) => prod.displayName === 'box')[0]
    .variants[0];
  const baseArray = prodVariantsNoBox.map((variant) => variant.customBoxDefaultQuantity);
  const pricesArr = prodVariantsNoBox.map((item) => item.price);

  //----------------------------
  // Cart Processing logic
  //----------------------------
  const cartLineItemVariants = cart?.lineItems
    ?.map((item) => {
      return { sku: item.variant.sku, ...item };
    })
    .filter((item) => customBoxSkus.includes(item?.sku));
  const cartSubscriptionInverval = cart?.lineItems?.find(
    (item) => item.variant.sku === 'box-builder'
  )?.subscriptionLineItems[0]?.intervalLength;

  //----------------------------
  // State Values
  //----------------------------
  const [selectedTab, setSelectedTab] = useState(1);
  const [disabledError, setDisabledError] = useState('Please select a delivery option');
  const [showDeliveryError, setShowDeliveryError] = useState(false);
  const [cartTotalArr, setCartTotalArr] = useState(baseArray);
  const [activeTotalPrice, setActiveTotalPrice] = useState(parseFloat(0.0));
  const [activeTotalQuantity, setActiveTotalQuantity] = useState(totalsArr.reduce((a, b) => a + b));
  const [cartInterval, setCartInterval] = useState(null);

  //----------------------------
  // UseEffects
  //----------------------------

  useEffect(() => {
    setTotalsArr(baseArray);
  }, []);

  useEffect(() => {
    const cartBasedArray = cartLineItemVariants
      ? getArrayFromLineItems(cartLineItemVariants)
      : null;
    if (cartBasedArray && !isModalOpen) {
      const newTotalArr = totalsArr.map((element, index) => {
        return prodVariantsNoBox[index].increment * (cartBasedArray[index] ?? 0);
      });
      setCartTotalArr(newTotalArr);
    } else if (!cartBasedArray && !isModalOpen) {
      const storedArray = JSON.parse(localStorage.getItem('totalsArrRef') ?? null);
      storedArray && setCartTotalArr(storedArray);
    }
  }, [JSON.stringify(cartLineItemVariants), isModalOpen]);

  useEffect(() => {
    const urlParams =
      typeof window !== 'undefined' ? new URLSearchParams(window?.location?.search) : null;
    if (urlParams.has('openWidget')) {
      setTimeout(() => {
        openModal();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    const emptyCart = !cartLineItemVariants?.length;
    const storedDeliverySelection = localStorage.getItem('intervalRef')
      ? JSON.parse(localStorage.getItem('intervalRef'))
      : null;
    const newInterval = intervals.find(
      (interval) => cartSubscriptionInverval === interval.length
    ) ?? {
      name: 'Buy Once (Regular Price)',
    };
    const deliverySectionOption = emptyCart ? storedDeliverySelection : newInterval;

    setTotalsArr(cartTotalArr);
    setSelectedInterval(deliverySectionOption);
    setCartInterval(deliverySectionOption);
  }, [cartTotalArr]);

  useEffect(() => {
    localStorage.setItem('intervalRef', JSON.stringify(selectedInterval));
    setDisabledError(
      !selectedInterval
        ? 'Please select a delivery option'
        : activeTotalQuantity < 20
        ? 'Please add at least 20 units to your box.'
        : null
    );
  }, [selectedInterval, activeTotalQuantity]);

  useEffect(() => {
    localStorage.setItem('totalsArrRef', JSON.stringify(totalsArr));
    setItemCount(activeTotalQuantity);
    if (activeTotalQuantity > 0) {
      setActiveTotalPrice(
        pricesArr
          .map((item, index) => (item * totalsArr[index]) / prodVariantsNoBox[index].increment)
          .reduce((a, b) => a + b)
          .toFixed(2)
      );
    } else {
      setActiveTotalPrice((0).toFixed(2));
    }
    (async () => {})();
  }, [activeTotalQuantity, totalsArr]);

  //----------------------------
  // handler functions
  //----------------------------
  const onIntervalChange = (e) => {
    if (e.target.value === 'Buy Once (Regular Price)') {
      setSelectedInterval({ name: e.target.value });
    } else {
      const newInterval = intervals.find((s) => s.name === e.target.value) ?? null;
      setSelectedInterval(newInterval);
    }
  };

  const handleShowDeliveryError = () => {
    setShowDeliveryError(true);
    const timeout = setTimeout(() => {
      const deliverySection = document.getElementById('deliverySection');
      deliverySection && deliverySection.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  };

  //----------------------------
  // Promo message logic
  //----------------------------
  // Show a default message (product.subtitle) when no promo is running
  const promoIsLive =
    new Date(product.widgetPromoStartTime).getTime() < new Date().getTime() &&
    new Date(product.widgetPromoEndTime).getTime() > new Date().getTime()
      ? { subPromoMessage, nonSubPromoMessage, promoImage }
      : {
          subPromoMessage: product.subtitle,
          nonSubPromoMessage: product.subtitle,
          promoImage: null,
        };

  const totalPrice = Number(activeTotalPrice) + Number(product.price);

  return (
    <>
      <BlurryOverlay boxPopUpOpen={isModalOpen} />
      {isModalOpen && (
        <img
          height="1"
          width="1"
          style={{ borderStyle: 'none' }}
          alt=""
          src="https://insight.adsrvr.org/track/pxl/?adv=7eghy5v&ct=0:wl0eebr&fmt=3"
        />
      )}

      {/* Entire Tab section */}
      <Flex
        sx={{
          position: 'fixed',
          bottom: isModalOpen ? 0 : '-150%',
          left: 0,
          width: '100%',
          p: [0, 0, '3rem 4rem'],
          backgroundColor: 'lightPurple',
          borderRadius: '20px 20px 0 0',
          borderColor: 'alizarinCrimson',
          borderWidth: '0.2rem',
          borderStyle: 'solid',
          transition: isModalOpen ? 'all 0.5s ease-in' : 'all 0.5s ease-in',
          zIndex: 2147483650,
          height: `${height * 0.9}px`,
          flexDirection: ['column', 'column', 'row'],
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={closeModal}
          sx={{
            height: '3.4rem',
            width: '3.4rem',
            position: 'absolute',
            top: '-4rem',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 7px 0px #00000029',
            borderColor: 'transparent',
            borderRadius: '2.5rem',
            display: 'flex',
            svg: {
              transform: 'scale(2)',
              height: '100%',
            },
          }}
        >
          <CloseIcon />
        </Button>
        <Flex
          sx={{
            flexDirection: 'column',
            width: ['100%', '100%', '75%'],
            minWidth: ['100%', '100%', '50%'],
            height: '100%',
            mr: [0, 0, '2.6rem'],
          }}
        >
          <Flex
            sx={{
              height: ['7.5rem', '7.5rem', '7rem'],
              minHeight: ['7.5rem', '7.5rem', '7rem'],
              width: ['100%', '100%', '100%'],
              minWidth: ['100%', '100%', '100%'],
              mb: [0, 0, '3rem'],
            }}
          >
            {tabArray.map((tab, index) => {
              const isThisSelected = index + 1 === selectedTab;
              return (
                <TabSelector
                  key={index}
                  isThisSelected={isThisSelected}
                  tab={tab}
                  index={index}
                  setSelectedTab={setSelectedTab}
                />
              );
            })}
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
              height: ['calc(100% - 7.5rem)', 'calc(100% - 7.5rem)', 'calc(100% - 7rem)'],
              width: '100%',
            }}
          >
            {/*  Box builder Tab */}
            {selectedTab === 1 && (
              <BuildABoxTab
                product={product}
                currentVariant={currentVariant}
                selectedInterval={selectedInterval}
                intervals={intervals}
                setSelectedInterval={setSelectedInterval}
                selectVariant={selectVariant}
                setTotalsArr={setTotalsArr}
                activeTotalPrice={activeTotalPrice}
                activeTotalQuantity={activeTotalQuantity}
                setActiveTotalQuantity={setActiveTotalQuantity}
                onIntervalChange={onIntervalChange}
                regularPrice={regularPrice}
                sku={sku}
                totalsArr={totalsArr}
                prodVariantsNoBox={prodVariantsNoBox}
                disabledError={disabledError}
                showDeliveryError={showDeliveryError}
                promoIsLive={promoIsLive}
                totalPrice={totalPrice}
              />
            )}

            {/* Details Tab  */}
            {selectedTab === 2 && <BuildABoxDetailsTab />}

            {/* Reviews Tab */}
            {selectedTab === 3 && <BuildABoxReviewsTab />}
          </Flex>
        </Flex>
        <WidgetAddToBag
          disabledError={disabledError}
          selectedInterval={selectedInterval}
          cartInterval={cartInterval}
          isSubscription={
            selectedInterval && selectedInterval.name !== 'Buy Once (Regular Price)'
              ? selectedInterval
              : null
          }
          prodVariantsNoBox={prodVariantsNoBox}
          prodVariants={prodVariants}
          subscription={subscription}
          prodBox={prodBox}
          increment={increment}
          maxQuantity={maxQuantity}
          activeTotalQuantity={activeTotalQuantity}
          product={product}
          totalsArr={totalsArr}
          cartTotalArr={cartTotalArr}
          activeTotalPrice={activeTotalPrice}
          intervals={intervals}
          onIntervalChange={onIntervalChange}
          setTotalsArr={setTotalsArr}
          isItWidget
          showDeliveryError={showDeliveryError}
          handleShowDeliveryError={handleShowDeliveryError}
          setSelectedTab={setSelectedTab}
          promoIsLive={promoIsLive}
          totalPrice={totalPrice}
        />
      </Flex>
    </>
  );
};
